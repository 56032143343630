import t from '@jetshop/intl';
import React, { useState } from 'react';
import { styled } from 'linaria/react';
import useGeolocation from '@jetshop/core/hooks/useGeolocation';

import { SmallHeading } from '../ui/Headings';
import Button from '../ui/Button';
import { theme } from '../Theme';
import { ReactComponent as LocationSvg } from '../../svg/Location.svg';
import { ReactComponent as CrossSvg } from '../../svg/Cross.svg';

/*==============================================================================
  # Styles
==============================================================================*/

const UserLocationContainer = styled('div')`
  position: relative;
  width: 100%;
  background: ${theme.colors.lightgrey};
  text-align: center;
  padding: 30px;
  margin-top: 30px;

  ${theme.above.md} {
    display: none;
  }
`;

const Inner = styled('div')`
  width: 100%;
  max-width: 260px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ul-icon-close {
    position: absolute;
    top: 15px;
    right: 15px;

    background: transparent;
    box-shadow: none;
    border: 0;

    svg {
      stroke: ${theme.colors.black};
    }
  }

  .ul-icon-circle {
    padding: 16px;
    background: ${theme.colors.grey};
    border-radius: 50%;
  }
`;

const Title = styled(SmallHeading)`
  margin: 15px auto;
`;

/*==============================================================================
  # Component
==============================================================================*/

const UserLocation = ({ setUserLocation }) => {
  const [hide, setHide] = useState(false);

  const { getPosition, isAvailable, position } = useGeolocation({
    onSuccess: ({ coords: { latitude, longitude } }) =>
      setUserLocation({ latitude, longitude }),
    onError: err => console.log(err)
  });

  if (hide || !isAvailable || position) return null;

  return (
    <UserLocationContainer>
      <Inner>
        <button
          aria-label="Close"
          className="ul-icon-close"
          onClick={() => setHide(true)}
        >
          <CrossSvg />
        </button>

        <span className="ul-icon-circle">
          <LocationSvg />
        </span>

        <Title>{t('Find the closest store using your location')}</Title>
        <Button aria-label="Share location" dark={true} onClick={getPosition}>
          {t('Share location')}
        </Button>
      </Inner>
    </UserLocationContainer>
  );
};

export default UserLocation;
