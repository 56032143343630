import React, { Fragment } from 'react';
import { styled } from 'linaria/react';
import { default as Image } from '@jetshop/ui/Image';
import Container from '../../Layout/Container';
import Text from '../../ui/Text';
import DynamicContent from '../../DynamicContent/DynamicContent';
import Breadcrumbs from '../../ui/Breadcrumbs';
import { Mega } from '../../ui/Headings';
import { theme } from '../../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const Title = styled(Mega)`
  color: ${theme.colors.slategrey};
  text-align: center;
  margin-top: 30px;

  ${theme.above.md} {
    margin-top: 50px;
  }
`;

const IntroText = styled(Text)`
  margin: 0px auto;
  margin-top: 30px;
  color: ${theme.colors.slategrey};
`;

const ImageContainer = styled('div')`
  position: relative;
  width: 100%;
  height: 300px;
  background-color: ${theme.colors.lightgrey};

  ${theme.above.sm} {
    height: 450px;
  }

  ${theme.above.md} {
    height: 500px;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const MainContent = ({
  breadcrumbs = [],
  title,
  introText,
  image,
  content
}) => {
  return (
    <Fragment>
      <Container>
        <Breadcrumbs items={breadcrumbs} />

        {image && image.src && (
          <ImageContainer>
            <Image
              alt={title}
              {...image}
              sizes={[1, 1, 1, 1, 1200]}
              aspect="1200:500"
              critical
              fillAvailableSpace={true}
              modifiedDate={image.modifiedDate}
            />
          </ImageContainer>
        )}

        <Title size="h1">{title}</Title>

        {introText && <IntroText content={introText} />}
      </Container>

      <Container marginTop={true}>
        <DynamicContent content={content} />
      </Container>
    </Fragment>
  );
};

export default MainContent;
