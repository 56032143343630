import React, { useMemo, useContext } from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import { Col } from '../../Layout/Container';
import BrandLogo from '../../ui/BrandLogo';
import { ButtonLink, ButtonInner } from '../../ui/Button';
import { theme } from '../../Theme';
import Link from '../../utility/Link';
import { ButtonStyle } from '../../ui/Button';
import { BlocksContext } from './HeroBlocks';
import { Above } from '@jetshop/ui/Breakpoints';

/*==============================================================================
  # Styles
==============================================================================*/

const StyledCol = styled(Col)`
  position: relative;
  height: 100%;
  flex-basis: auto;

  .content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    padding: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    color: ${theme.colors.text};

    &.text-color-black {
      color: ${theme.colors.textblack};
    }
    &.text-color-white {
      color: ${theme.colors.textwhite};
    }

    ${theme.below.md} {
      padding: 20px;
    }
  }

  .content-top {
    flex: auto;
    display: flex;
    flex-direction: column;
    gap: 0.375rem;

    &.text-align-top {
      justify-content: flex-start;
    }
    &.text-align-center {
      justify-content: center;
    }
    &.text-align-bottom {
      justify-content: flex-end;
    }

    h1.font-size-72px {
      font-size: 4.5rem;
    }
    h1.font-size-48px {
      font-size: 3rem;
    }
    h1.font-size-32px {
      font-size: 2rem;
    }
    h2 {
      font-size: 1.75rem;
    }
    p {
      font-size: 1rem;
    }

    h1,
    h2 {
      line-height: 1;
    }

    h1,
    h2 {
      letter-spacing: 1px;
    }

    ${theme.below.md} {
      h1 {
        font-size: 1.75rem !important;
      }
      h2 {
        font-size: 1.125rem;
      }
      p {
        font-size: 0.75rem;
        line-height: 1.25;
      }
    }
  }

  .content-bottom.margin-top {
    margin-top: 1rem;
  }

  h1,
  h2 {
    text-transform: none;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .logo-wrapper {
    margin: 0 auto;
  }
`;

const ButtonWrapper = styled('div')`
  .cta-mockup {
    ${ButtonStyle};
  }

  .text-button {
    font-family: ${theme.fonts.heading};
    color: inherit;
    font-size: 1.25rem;
    letter-spacing: 1px;
    text-decoration: underline;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const BlockButton = ({ link, cta, whiteCTA, children }) => (
  <ButtonWrapper>
    {link ? (
      cta ? (
        <ButtonLink white={whiteCTA} to={link}>
          {children}
        </ButtonLink>
      ) : (
        <Link className="text-button" to={link}>
          {children}
        </Link>
      )
    ) : cta ? (
      <div className={`cta-mockup ${whiteCTA ? 'white' : ''}`}>
        <ButtonInner>{children}</ButtonInner>
      </div>
    ) : (
      <div className="text-button">{children}</div>
    )}
  </ButtonWrapper>
);

const HeroBlock = ({
  backgroundImage,
  backgroundImageAlt,
  heading,
  headingUppercase,
  subheading,
  subheadingUppercase,
  bodyText,
  bodyTextUppercase,
  headingFontSizeDesktop,
  textAlignment,
  textColor,
  logotypeImage,
  logotypeImageAlt,
  linkURL,
  imageLink,
  buttonText,
  buttonType,
  buttonCTAColor,
  desktopAspect
}) => (
  <StyledCol>
    {backgroundImage && (
      <Above breakpoint="md">
        {matches =>
          matches ? (
            <Image
              src={backgroundImage}
              alt={backgroundImageAlt || heading}
              quality={80}
              sizes={[1, 1, 1, 67 / 100, 965]}
              aspect="1:1"
              critical
              fillAvailableSpace
            />
          ) : (
            <Image
              src={backgroundImage}
              alt={backgroundImageAlt || heading}
              quality={80}
              sizes={1}
              aspect="3:2"
              critical
              cover
            />
          )
        }
      </Above>
    )}
    <div className={`content text-color-${textColor}`}>
      <div className={`content-top text-align-${textAlignment}`}>
        {logotypeImage && (
          <div className="logo-wrapper">
            <Above breakpoint="md">
              {matches =>
                matches ? (
                  <BrandLogo
                    src={logotypeImage}
                    alt={logotypeImageAlt || ''}
                    width="100"
                  />
                ) : (
                  <BrandLogo
                    src={logotypeImage}
                    alt={logotypeImageAlt || ''}
                    width="80"
                  />
                )
              }
            </Above>
          </div>
        )}
        {!!heading && (
          <h1
            className={`${
              headingUppercase ? 'uppercase' : ''
            } font-size-${headingFontSizeDesktop}`}
          >
            {heading}
          </h1>
        )}
        {!!subheading && (
          <h2 className={subheadingUppercase ? 'uppercase' : ''}>
            {subheading}
          </h2>
        )}
        {!!bodyText && (
          <p className={bodyTextUppercase ? 'uppercase' : ''}>{bodyText}</p>
        )}
      </div>
      {!!buttonText && !!linkURL && (
        <div
          className={`content-bottom ${
            textAlignment === 'bottom' ? 'margin-top' : ''
          }`}
        >
          <BlockButton
            link={!imageLink ? linkURL : ''}
            cta={buttonType === 'CTA'}
            whiteCTA={buttonCTAColor === 'inverse'}
          >
            {buttonText}
          </BlockButton>
        </div>
      )}
    </div>
  </StyledCol>
);

const HeroBlockWrapper = props => {
  const { desktopAspect } = useContext(BlocksContext);

  const newProps = useMemo(() => {
    const newProps = {};
    Object.keys(props).forEach(key => {
      const prop = props[key]?.value;
      const propsValue = prop?.hasOwnProperty('value') ? prop.value : prop;
      newProps[key] = propsValue;
    });
    return newProps;
  }, [props]);

  const { linkURL, imageLink } = newProps;

  return imageLink && !!linkURL ? (
    <Link to={linkURL}>
      <HeroBlock desktopAspect={desktopAspect} {...newProps} />
    </Link>
  ) : (
    <HeroBlock desktopAspect={desktopAspect} {...newProps} />
  );
};

export default HeroBlockWrapper;
