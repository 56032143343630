import React from 'react';
import { styled } from 'linaria/react';
import { Helmet } from 'react-helmet-async';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image';
import { Mega, SubHeading } from '../ui/Headings';
import { Row, Col } from '../Layout/Container';
import { LocationStateContext } from '../StoreLocator/StoreLocator';
import { useLocationState } from '../StoreLocator/useLocationState';
import StoreMap from '../StoreLocator/StoreMap';
import { ImageCol, TextCol } from '../DynamicContent/DynamicContent';
import { theme } from '../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const Title = styled(Mega)`
  margin-bottom: 30px;
`;

const StyledSubHeading = styled(SubHeading)`
  margin-bottom: 5px;
`;

const Box = styled('div')`
  margin-bottom: 30px;
`;

const Line = styled('div')`
  hr {
    display: none;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }

  ${theme.fontSizes.regular}
`;

const MapCol = styled(Col)`
  margin-top: 30px;
  min-height: 500px;
`;

const Iframe = styled('iframe')`
  width: 100%;
  height: 60vh;
`;

const StoreRow = styled(Row)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  ${theme.below.md} {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const StoreDetail = ({ store }) => {
  const locationState = useLocationState();

  let stores = [];
  stores.push(store);

  const title = store.name;
  const description = store.description;
  const imageSrc = store.other
    ? store.other.replace(/<\/?[^>]+(>|$)/g, '').trim()
    : null;

  // NOTE: As this is a special case we'll add a placeholder to modifiedDate so it's not
  // forgotten in future update when this pages is sorted out.
  const modifiedDate = undefined;

  const _iframeSrc = store.address2
    ? store.address2.replace(/<\/?[^>]+(>|$)/g, '').trim()
    : null;
  const iframeSrc = _iframeSrc
    ? _iframeSrc.match(/^[a-zA-Z]+:\/\//)
      ? _iframeSrc
      : `https://${_iframeSrc}`
    : null;

  return (
    <LocationStateContext.Provider value={locationState}>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="description" content={description} />
      </Helmet>

      <Title size="h1">{title}</Title>

      <StoreRow>
        {imageSrc && (
          <ImageCol>
            <Image
              alt={title}
              src={imageSrc}
              sizes={[1, 1, 1 / 2, 1 / 2, 1 / 2]}
              quality={80}
              aspect="1:1"
              modifiedDate={modifiedDate}
            />
          </ImageCol>
        )}

        <TextCol>
          {store.description && (
            <Box>
              <StyledSubHeading size="h2">{t('Beskrivning')}</StyledSubHeading>
              <Line dangerouslySetInnerHTML={{ __html: store.description }} />
            </Box>
          )}

          {store.openHours && (
            <Box>
              <StyledSubHeading size="h2">
                {t('Opening hours')}
              </StyledSubHeading>
              <Line dangerouslySetInnerHTML={{ __html: store.openHours }} />
            </Box>
          )}

          {store.address1 && (
            <Box>
              <StyledSubHeading size="h2">{t('Address')}</StyledSubHeading>
              <Line dangerouslySetInnerHTML={{ __html: store.address1 }} />
              {store.city && (
                <Line dangerouslySetInnerHTML={{ __html: store.city }} />
              )}
              {store.region && (
                <Line dangerouslySetInnerHTML={{ __html: store.region }} />
              )}
            </Box>
          )}

          {store.contact && (
            <Box>
              <StyledSubHeading size="h2">{t('Kontakt')}</StyledSubHeading>
              <Line dangerouslySetInnerHTML={{ __html: store.contact }} />
            </Box>
          )}
        </TextCol>
      </StoreRow>

      {iframeSrc && (
        <Row>
          <Iframe src={iframeSrc} />
        </Row>
      )}

      <Row>
        <MapCol col={12}>
          <StoreMap stores={stores} userLocation={{}} />
        </MapCol>
      </Row>
    </LocationStateContext.Provider>
  );
};

export default StoreDetail;
