import React from 'react';
import { styled } from 'linaria/react';
import Link from '../../utility/Link';
import Image from '@jetshop/ui/Image/Image';
import { SubHeading } from '../../ui/Headings';
import { Col } from '../../Layout/Container';
import { theme } from '../../Theme';

const Card = styled(Col)`
  padding: 2.5px;

  ${theme.above.sm} {
    padding: 2.5px;
  }

  > a {
    text-decoration: none;

    &:hover {
      .overline {
        color: ${theme.colors.slategrey};
      }
      .description {
        color: ${theme.colors.black};
      }
      img {
        //transform: scale(1.01);
      }
    }
  }

  .cardInner {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    img {
      object-fit: cover !important;
      font-family: 'object-fit: cover' !important;
      transition: all 500ms ${theme.easings.secondary}, opacity 500ms ease 0s !important;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 10px;

    ${theme.above.sm} {
      padding-top: 15px;
    }
  }

  .overline,
  .description {
    transition: all 250ms ${theme.easings.secondary};
  }

  .overline {
    color: ${theme.colors.textgrey};
    font-weight: 400;
    letter-spacing: 1.8px;
  }

  .description {
    margin-bottom: 30px;
    color: ${theme.colors.slategrey};
  }
`;

const CardLarge = styled(Col)`
  padding: 2.5px;
   
  > a {
    text-decoration: none !important;
  }

  ${theme.above.sm} {
    padding: 2.5px;
  }

  .cardInner {
    position: relative;

    .overline,
    .description {
      transition: all 350ms ${theme.easings.secondary};
    }

    .overline {
      font-size: 1.3rem;
      line-height: 1.39;
      letter-spacing: 1;
    }

    .description {
      font-size: 3rem;
      line-height: 1.25;
      letter-spacing: .5px;
      margin-bottom: 0px;
    }

    &:hover {
      .overline,
      .description {
        color: ${theme.colors.black};
      }

    }
  }

  .content-large {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 300px;
    padding: 15px;
    color: ${theme.colors.white};
    text-decoration: none !important;

    ${theme.above.sm} {
      min-height: 450px;
    }

    ${theme.above.md} {
      min-height: 500px;
    }
  }
  }
`;

const ImageLarge = styled(Image)`
  position: absolute !important;
  height: 100% !important;
  padding-bottom: 0px !important;

  img {
    object-fit: cover !important;
    transition: all 500ms ${theme.easings.secondary};
  }
`;

export const ImagePodLarge = ({ image, overline, description, linkurl }) => (
  <CardLarge>
    <Link to={linkurl?.value?.value}>
      <div className="cardInner">
        {image && (
          <ImageLarge
            alt={overline.value.value}
            src={image.value.value}
            sizes={[1, 1 / 2, 1200]}
            aspect="1200:500"
            quality={80}
          />
        )}
        <div className="content-large">
          {overline && <h3 className="overline">{overline.value.value}</h3>}
          {description && (
            <SubHeading size="h2" className="description">
              {description.value.value}
            </SubHeading>
          )}
        </div>
      </div>
    </Link>
  </CardLarge>
);

export default function ImagePod({ image, overline, description, linkurl }) {
  //Sizes are for 3 in a row
  const sizes = [1, 1 / 2, 1 / 3, 1 / 3, 1 / 3];
  const aspect = '6:5';

  return (
    <Card>
      <Link to={linkurl?.value?.value}>
        <div className="cardInner">
          {image && (
            <Image
              alt={overline.value.value}
              src={image.value.value}
              sizes={sizes}
              quality={80}
              aspect={aspect}
            />
          )}
          <div className="content">
            {overline && <h4 className="overline">{overline.value.value}</h4>}
            {description && (
              <SubHeading className="description">
                {description.value.value}
              </SubHeading>
            )}
          </div>
        </div>
      </Link>
    </Card>
  );
}
