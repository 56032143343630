import React from 'react';
import { styled } from 'linaria/react';
import striptags from 'striptags';
import { Shortcodes } from '@jetshop/flight-shortcodes';
import { Row, Col } from '../Layout/Container';
import { TextStyle } from '../ui/Text';
import { theme } from '../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const TextWrapper = styled('div')`
  ${TextStyle}
  ${theme.fontSizes.description}
  width: 100%;
  max-width: 100% !important;
  color: ${theme.colors.slategrey};

  img {
    max-width: 100%;
  }

  margin: 0 auto;

  .break {
    margin-bottom: 10px;
  }

  .row + .break,
  .row + .break + .break {
    display: none;
  }
`;

const StyledRow = styled(Row)`
  & > .break {
    display: none;
  }
`;

const StyledCol = styled(Col)`
  ${theme.above.sm} {
    order: ${({ orderSm }) => (orderSm ? orderSm : 0)};
  }

  ${theme.above.md} {
    order: ${({ order, orderMd }) => (orderMd ? orderMd : order ? order : 0)};
  }

  ${theme.above.lg} {
    order: ${({ order, orderLg }) => (orderLg ? orderLg : order ? order : 0)};
  }

  ${theme.above.xl} {
    order: ${({ order, orderXl }) => (orderXl ? orderXl : order ? order : 0)};
  }
`;

export const TextCol = styled(StyledCol)`
  ${theme.above.md} {
    &.order-1,
    &.order-2 {
      padding: 30px;
      padding-bottom: 50px;
    }

    &.order-1 {
      padding-left: 15px;
    }

    &.order-2 {
      padding-right: 15px;
    }
  }

  ${theme.above.lg} {
    &.order-1,
    &.order-2 {
      padding: 40px;
      padding-bottom: 60px;
    }
  }
`;

export const ImageCol = styled(StyledCol)`
  ${theme.above.md} {
    min-height: 450px;

    img {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover !important;
    }
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const RowComponent = ({ children, ...rest }) => {
  return (
    <StyledRow className="row" {...rest}>
      {children}
    </StyledRow>
  );
};

const ColComponent = ({ children, col, sm, md, lg, xl, ...rest }) => {
  let colSpan = {
    col: col ? col : 12,
    sm: sm,
    md: md ? md : 6,
    lg: lg,
    xl: xl
  };

  return (
    <StyledCol {...colSpan} {...rest}>
      {children}
    </StyledCol>
  );
};

const TextColComponent = ({
  children,
  col,
  sm,
  md,
  lg,
  xl,
  order,
  className,
  ...rest
}) => {
  let colSpan = {
    col: col ? col : 12,
    sm: sm,
    md: md ? md : 6,
    lg: lg,
    xl: xl
  };

  let classes = className;
  if (order) classes += ' order-' + order;

  return (
    <TextCol order={order} {...colSpan} className={classes} {...rest}>
      {children}
    </TextCol>
  );
};

const ImageColComponent = ({
  children,
  col,
  sm,
  md,
  lg,
  xl,
  order,
  className,
  ...rest
}) => {
  let colSpan = {
    col: col ? col : 12,
    sm: sm,
    md: md ? md : 6,
    lg: lg,
    xl: xl
  };

  let classes = className;
  if (order) classes += ' order-' + order;

  return (
    <ImageCol order={order} {...colSpan} className={classes} {...rest}>
      {children}
    </ImageCol>
  );
};

const components = {
  Rad: RowComponent,
  Kolumn: ColComponent,
  TextKolumn: TextColComponent,
  BildKolumn: ImageColComponent
};

export default function DynamicContent({ content, additionalComponents }) {
  const allComponents = { ...components, additionalComponents };

  const allowedTags = [
    'a',
    'span',
    'strong',
    'i',
    'img',
    'ul',
    'ol',
    'li',
    'hr',
    'br',
    'blockquote',
    'h2',
    'h3',
    'h4',
    'table',
    'tbody',
    'tfooter',
    'tr',
    'td',
    'th',
    'iframe'
  ];

  // Preserve center alignment if present
  const isCentered = /^<p style="text-align: center;[\s\S]*?>/.test(content);

  const strippedContent = striptags(
    content,
    allowedTags,
    '<div className="break"></div>'
  );

  return (
    <TextWrapper full={true} style={isCentered ? { textAlign: 'center' } : {}}>
      <Shortcodes content={strippedContent} components={allComponents} />
    </TextWrapper>
  );
}
