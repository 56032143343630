import React from 'react';
import { styled } from 'linaria/react';
import Container, { Row } from '../../Layout/Container';
import { Hero } from '../..//ui/Headings';
import BorderHeading from '../../ui/BorderHeading';
import { theme } from '../../Theme';
import ImagePod, { ImagePodLarge } from './ImagePod';

/*==============================================================================
  # Styles
==============================================================================*/

const StyledContainer = styled(Container)`
  overflow-x: hidden;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const ItemRow = styled(Row)`
  margin-left: -2.5px;

  ${theme.below.md} {
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow: auto;
    margin-left: -2.5px;
  }

  ${theme.above.md} {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const Inspiration = ({ title, children }) => {
  const imagePods = children.map(imagepod => imagepod.props);
  const topImagePod = imagePods[0];
  const bottomImagePods = imagePods.slice(1);

  return (
    <Container marginTop>
      <StyledContainer>
        {title && (
          <BorderHeading align="center">
            <Hero size="h2">{title.value.value}</Hero>
          </BorderHeading>
        )}

        {topImagePod && (
          <ItemRow>
            <ImagePodLarge
              image={topImagePod.image}
              overline={topImagePod.overline}
              description={topImagePod.description}
              linkurl={topImagePod.linkurl}
            />
          </ItemRow>
        )}

        {bottomImagePods && (
          <ItemRow>
            {bottomImagePods.map((imagepod, i) => (
              <ImagePod
                key={`imagepod-${i}`}
                image={imagepod.image}
                overline={imagepod.overline}
                description={imagepod.description}
                linkurl={imagepod.linkurl}
              />
            ))}
          </ItemRow>
        )}
      </StyledContainer>
    </Container>
  );
};

export default Inspiration;
