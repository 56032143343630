import React from 'react';
import StoreLocator from '../../StoreLocator/StoreLocator';

const StoreLocatorComponent = ({ showStore }) =>
  showStore && (
    <>
      <StoreLocator />
    </>
  );

export default StoreLocatorComponent;
