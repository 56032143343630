import React from 'react';
import { styled } from 'linaria/react';
import Container from '../../Layout/Container';
import { SuperMega, Hero } from '../../ui/Headings';
import { theme } from '../../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const CtaContainer = styled(Container)`
  text-align: center;
  color: ${theme.colors.slategrey};

  .inner {
    width: 100%;
  }

  .title {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      position: relative;
      display: block;
      padding: 0 0.3em;
      background: ${theme.colors.white};
      z-index: 2;
    }

    &::before {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      height: 2px;
      background-color: ${theme.colors.mediumdarkgrey};
      width: 100%;
      z-index: 1;
    }
  }

  .sub-title {
    margin-bottom: 10px;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const TitleText = ({ title, subtitle, marginTop }) => {
  return (
    <CtaContainer fullWidth marginTop={marginTop}>
      <div className="inner">
        <SuperMega className="title">
          <span>{title.value.value}</span>
        </SuperMega>
        <Hero className="sub-title" size="p">
          {subtitle.value.value}
        </Hero>
      </div>
    </CtaContainer>
  );
};

export default TitleText;
