import React from 'react';
import { styled } from 'linaria/react';
import Container, { Row, Col } from '../../Layout/Container';
import BrandLogo from '../../ui/BrandLogo';
import { ButtonLink } from '../../ui/Button';
import { theme } from '../../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const BrandCol = styled(Col)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -10px 0px;
  padding: 0px;

  ${theme.above.xs} {
    padding: 0px 5px;
  }
`;

const Title = styled('h2')`
  font-size: 1.875rem;
  line-height: 1.3;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 30px;
`;

const Description = styled('p')`
  max-width: 740px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30px;
`;

const StyledBrandLogo = styled(BrandLogo)`
  margin: 10px;

  ${theme.below.md} {
    width: 100px;
  }

  div {
    padding-bottom: 60% !important;
  }
`;

const ButtonContainer = styled('div')`
  padding: 0px 15px;
  margin-top: 30px;
`;

/*==============================================================================
  # Component
==============================================================================*/

const Brands = ({ title, description, marginTop, children }) => {
  const brandsArray = children.filter(item => item.key !== 'link-0');
  const brands = brandsArray.map(brand => brand.props);

  const link = children.filter(item => item.key === 'link-0');
  const linkTitle = link && link?.[0].props?.['linkTitle']?.value?.value;
  const linkUrl = link && link?.[0].props?.['linkUrl']?.value?.value;

  return (
    <Container marginTop={marginTop?.value?.value}>
      <Title>{title?.value?.value}</Title>
      <Description>{description?.value?.value}</Description>
      <Container>
        <Row justify="center">
          <BrandCol col={12} md={10}>
            {brands?.length > 0 &&
              brands.map((b, i) => (
                <StyledBrandLogo
                  key={i}
                  width={140}
                  title={b.brandName.value.value}
                  to={b.brandLink.value.value}
                  src={b.brandImage.value.value}
                  alt={b.brandName.value.value}
                />
              ))}
          </BrandCol>
        </Row>

        {linkUrl && (
          <Row justify="center">
            <ButtonContainer>
              <ButtonLink to={linkUrl}>{linkTitle}</ButtonLink>
            </ButtonContainer>
          </Row>
        )}
      </Container>
    </Container>
  );
};

export default Brands;
