import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import LoadingPage from '../LoadingPage';
import { ContentEditorPage } from './StartPage';
import mainQuery from './MainStartPageQuery.gql';

const MainStartPage = () => {
  const main = useQuery(mainQuery);
  if (main.loading) return <LoadingPage />;
  if (!main.data) return null;
  return (
    <ContentEditorPage result={main.data.route} path="/"></ContentEditorPage>
  );
};

export default MainStartPage;
