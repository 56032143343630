import React from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as SearchSvg } from '../../svg/Search.svg';

import { theme } from '../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const SearchContainer = styled('div')`
  position: sticky;
  top: 64px;
  z-index: 3;
  padding: 13px 8px;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  background: white;
  width: 100%;

  ${theme.above.lg} {
    position: relative;
    top: 0;
    border-top: none;
  }

  input {
    width: 100%;
    height: 46px;
    padding: 0 50px 0 25px;
    border-radius: 27px;
    border: 1px solid ${theme.colors.grey};
    outline: none;
    font-family: ${theme.fonts.primary};
    ${theme.fontSizes.regular}
  }

  svg {
    position: absolute;
    top: 50%;
    right: 25px;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);

    stroke: transparent;
    fill: ${theme.colors.black};
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const StoreSearch = ({ search, setSearch }) => (
  <SearchContainer>
    <input
      value={search}
      onChange={e => {
        setSearch(e.target.value);
      }}
      placeholder="Sök efter butik"
    />
    <SearchSvg />
  </SearchContainer>
);

export default StoreSearch;
