import React, { useState, Fragment } from 'react';
import { Query } from 'react-apollo';
import { styled } from 'linaria/react';
import { Helmet } from 'react-helmet-async';
import { fuzzySearch } from '@jetshop/ui/fuzzySearch';
import { distance } from '@jetshop/core/helpers/distance';

import Container, { Row, Col } from '../Layout/Container';
import Breadcrumbs from '../ui/Breadcrumbs';
import LoadingPage from '../LoadingPage';
import StoreSearch from './StoreSearch';
import StoreList from './StoreList';
import StoreMap from './StoreMap';
import { useLocationState } from './useLocationState';
import { theme } from '../Theme';
import storeLocatorQuery from './StoreLocator.gql';
import routePageQuery from '../RouteQuery.gql';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { components } from '../ContentEditor';

/*==============================================================================
  # Styles
==============================================================================*/

const StoreLocatorContent = styled(Container)`
  padding-left: 0;
  padding-right: 0;
`;

const StyledResultContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;
  margin-top: 10px;
  margin-bottom: 40px;
`;

const ScrollRow = styled(Row)`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  max-height: 100%;
  min-height: 60vh;
  ${theme.below.md} {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
  }
`;

const ScrollCol = styled(Col)`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  margin-top: 30px;
  min-height: unset;

  ${theme.above.md} {
    min-height: 1px;
    order: 1;
    margin-top: 0px;
  }
`;

const MapCol = styled(Col)`
  min-height: unset;
  ${theme.above.md} {
    min-height: 1px;
    order: 2;
    height: 60vh;
    position: sticky;
    top: 10vh;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

export const LocationStateContext = React.createContext(null);

const StoreLocator = () => {
  const locationState = useLocationState();
  const [search, setSearch] = useState('');
  const [userLocation, setUserLocation] = useState({
    latitude: null,
    longitude: null
  });
  const [closestStore, setClosestStore] = useState(null);
  const [distances, setDistances] = useState({});

  const genDistances = stores => {
    if (userLocation.latitude == null) return;

    let distances = {};
    let closestStore = {};
    stores.forEach(store => {
      if (
        store.coordinates &&
        store.coordinates.latitude &&
        store.coordinates.longitude
      ) {
        const thisDistance = distance(userLocation, store.coordinates);

        distances[store.id] = thisDistance;

        // If we haven't got a store in the closestStore obj yet, set it to this one
        if (!closestStore.distance) {
          closestStore = {
            ...store,
            distance: thisDistance
          };
          return;
        }

        // If this store is closer than the one currently stored in closestStore,
        // override it
        if (thisDistance < closestStore.distance) {
          closestStore = { ...store, distance: thisDistance };
        }
      }
    });

    setDistances(distances);
    setClosestStore(closestStore);
  };

  let breadcrumbs = [
    {
      title: 'Våra butiker'
    }
  ];

  let metaTitle = 'Möbelbutiker - Inredning & Möbler';
  let metaDescription =
    'Välkommen till Svenska Hem och någon av våra möbelbutiker från Luleå i norr till Malmö i söder. Med mer än 50 års kunskap inom möbler och accessoarer kan vi med stolthet säga att vi kan det här med heminredning och design.';

  function getStorePageContent({ data, loading }) {
    if (loading) return <LoadingPage />;
    if (!data) return null;

    const { route, object } = data;

    metaTitle = object?.head?.title || metaTitle;
    metaDescription =
      object?.head?.metaTags?.find(x => x.name === 'description')?.content ||
      metaDescription;

    return (
      <ContentRenderer
        items={route?.object?.data?.items}
        components={components}
      />
    );
  }

  function getStoreData({ data, loading }) {
    if (loading) return <LoadingPage />;
    if (!data) return null;

    if (userLocation.latitude && !closestStore) genDistances(data.stores);

    let stores =
      search.length === 0
        ? data.stores
        : fuzzySearch({
            data: data.stores.map(store => ({
              ...store,
              address1: store.address1.replace(/class="[^"]*"/g, ''),
              name: store.name.replace(/class="[^"]*"/g, '')
            })),
            keys: ['name', 'address1'],
            searchTerm: search
          });

    function splitOpenHours(store) {
      if (!store.openHours) return store;
      // split on <hr /> and remove it and everything after it
      const openHours =
        store.openHours?.replace(/<hr\s*\/?>[\s\S]*/gi, '') || store?.openHours;

      return {
        ...store,
        openHours
      };
    }

    let sortedStores = stores
      .slice()
      .map(splitOpenHours)
      .sort((a, b) => a.name.localeCompare(b.name, 'sv'));

    const storeSearchProps = { search, setSearch };
    const storeListProps = {
      stores: sortedStores,
      userLocation,
      setUserLocation,
      distances
    };
    const storeMapProps = {
      stores: sortedStores,
      userLocation,
      closestStore,
      distances,
      search,
      setSearch
    };

    return (
      <LocationStateContext.Provider value={locationState}>
        <ScrollRow>
          <MapCol>
            <StoreMap {...storeMapProps} />
          </MapCol>

          <ScrollCol>
            <StoreSearch {...storeSearchProps} />
            <StoreList {...storeListProps} />
          </ScrollCol>
        </ScrollRow>
      </LocationStateContext.Provider>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <title>{metaTitle}</title>
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta name="description" content={metaDescription} />
      </Helmet>

      <Container>
        <Breadcrumbs items={breadcrumbs} />

        <StyledResultContainer>
          <Query query={routePageQuery} variables={{ path: '/butiksinfo' }}>
            {getStorePageContent}
          </Query>
        </StyledResultContainer>

        <StoreLocatorContent>
          <Query query={storeLocatorQuery}>{getStoreData}</Query>
        </StoreLocatorContent>
      </Container>
    </Fragment>
  );
};

export default StoreLocator;
