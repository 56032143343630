import React from 'react';
import { styled } from 'linaria/react';

import Container from '../../Layout/Container';
import { Heading } from '../../ui/Headings';
import { ButtonLink } from '../../ui/Button';
import { theme } from '../../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const CustomizedContainer = styled(Container)`
  max-width: 1400px;
  margin-bottom: 14px;

  &.fullWidth {
    max-width: initial;
    padding-left: 0;
    padding-right: 0;
  }

  .color-slategrey {
    color: ${theme.colors.slategrey};
  }
`;

const StyledHeading = styled(Heading)`
  font-size: 1.75rem;

  &.size-sm {
    font-size: 1.25rem;
  }

  &.size-md {
    font-size: 1.5rem;
  }

  &.size-lg {
    font-size: 1.75rem;
  }

  &.size-xl {
    font-size: 2rem;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.align-center {
    text-align: center;
  }

  &.align-right {
    text-align: right;
  }

  &.align-left {
    text-align: left;
  }

  &.bottom-margin {
    margin-bottom: 20px;

    ${theme.above.md} {
      margin-bottom: 30px;
    }

    ${theme.above.lg} {
      margin-bottom: 40px;
    }
  }

  ${theme.above.md} {
    &.size-sm {
      font-size: 1.5rem;
    }

    &.size-md {
      font-size: 2rem;
    }

    &.size-lg {
      font-size: 2.5rem;
    }

    &.size-xl {
      font-size: 3rem;
    }
  }
`;

const StyledParagraph = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  &.flex-left {
    justify-content: flex-start;
  }

  &.flex-right {
    justify-content: flex-end;
  }

  p {
    font-size: 1rem;
    font-family: ${theme.fonts.primary};

    &.text-sm {
      font-size: 0.875rem;
    }

    &.text-md {
      font-size: 1rem;
    }

    &.text-lg {
      font-size: 1.25rem;
    }

    &.uppercase {
      text-transform: uppercase;
    }

    &.align-center {
      text-align: center;
    }

    &.align-right {
      text-align: right;
    }

    &.align-left {
      text-align: left;
    }

    ${theme.above.md} {
      max-width: 50%;

      &.wideText {
        max-width: initial;
      }
    }
  }
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

/*==============================================================================
  # Component
==============================================================================*/
const getValue = prop => prop?.value?.value;
const TextBlock = ({
  title,
  titleSize,
  titleUppercase,
  titleColor,
  titleAlignment,
  titleToTextGap,
  fullWidthContainer,
  text,
  textSize,
  textUppercase,
  textColor,
  textAlignment,
  textWide,
  linkTitle,
  linkUrl
}) => {
  title = getValue(title) || '';
  titleSize = getValue(titleSize) || 'md';
  titleUppercase = getValue(titleUppercase) === 'true' ? 'uppercase' : '';
  titleColor = getValue(titleColor) || 'black';
  titleAlignment = getValue(titleAlignment) || 'center';
  titleToTextGap = getValue(titleToTextGap) || 'false';

  fullWidthContainer =
    getValue(fullWidthContainer) === 'true' ? 'fullWidth' : '';

  text = getValue(text) || '';
  textSize = getValue(textSize) || 'md';
  textUppercase = getValue(textUppercase) === 'true' ? 'uppercase' : '';
  textColor = getValue(textColor) || 'black';
  textAlignment = getValue(textAlignment) || 'center';
  textWide = getValue(textWide) === 'true' ? 'wideText' : '';

  linkTitle = getValue(linkTitle) || '';
  linkUrl = getValue(linkUrl) || '';

  const link = { to: linkUrl, alt: linkTitle };

  return (
    <CustomizedContainer className={fullWidthContainer}>
      {title && (
        <StyledHeading
          size="h1"
          className={`${titleUppercase} align-${titleAlignment} color-${titleColor} size-${titleSize} ${
            titleToTextGap ? 'bottom-margin' : ''
          }`}
        >
          {title}
        </StyledHeading>
      )}
      {text && (
        <StyledParagraph className={`flex-${textAlignment}`}>
          <p
            className={`text-${textSize} ${textUppercase} align-${textAlignment} color-${textColor} ${textWide}`}
          >
            {text}
          </p>
        </StyledParagraph>
      )}
      {linkUrl && linkTitle && (
        <LinkContainer>
          <ButtonLink {...link}>{linkTitle}</ButtonLink>
        </LinkContainer>
      )}
    </CustomizedContainer>
  );
};

export default TextBlock;
