import React, { useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import { Heading } from '../ui/Headings';
import { theme } from '../Theme';
import getContentByIdQuery from '../GetContentByIdQuery.gql';
import { useQuery } from '@apollo/react-hooks';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { components } from '../ContentEditor';

const MyPagesStartPageWrapper = styled('div')`
  .list-order {
    background-color: white;
  }

  ${theme.below.sm} {
    padding: 0px;
    margin-top: 24px;
  }

  .section-wrapper {
    width: 100%;

    ${theme.below.sm} {
      padding-left: 12px;
      padding-right: 12px;
      margin-top: 24px;
    }
  }

  .section-title {
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    height: 40px;
  }

  .list-order {
    margin-bottom: 18px;
  }

  .show-all-orders {
    color: ${theme.colors.slategrey};
    font-size: 0.875em;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2em;

    :hover {
      opacity: 0.8;
    }

    svg {
      margin-left: 0.5em;
      fill: currentColor;
    }
  }
`;

export const SubHeading = styled(Heading)`
  margin-bottom: 20px;
`;

export const Line = styled('p')`
  margin-bottom: 10px;
`;

function MyPagesStartPage() {
  const [contentPageId, setContentPageId] = useState(theme.myPagesIdProd);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const url = window.location.href;
      if (url.includes('svenskahem-test')) {
        setContentPageId(theme.myPagesIdTest);
        console.log('TEST!');
      }
    }
  }, []);

  const { data, loading } = useQuery(getContentByIdQuery, {
    variables: {
      ids: contentPageId
    }
  });

  const items = data?.content?.content?.[0]?.items;

  if (loading) return null;
  return (
    <MyPagesStartPageWrapper className="main-wrapper">
      <div className="section-wrapper">
        {items ? (
          <ContentRenderer items={items} components={components} />
        ) : null}
      </div>
    </MyPagesStartPageWrapper>
  );
}

export default MyPagesStartPage;
