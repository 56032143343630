import React from 'react';
import { styled } from 'linaria/react';
import Container, { Row } from '../../Layout/Container';
import { theme } from '../../Theme';
import CategoryGridItem from './CategoryGridItem';

/*==============================================================================
  # Styles
==============================================================================*/

const StyledContainer = styled(Container)`
  overflow-x: hidden;
  margin-top: 3rem;
`;

const ItemRow = styled(Row)`
  margin-left: -2.5px;
  justify-content: flex-start;

  ${theme.below.md} {
    flex-wrap: nowrap;
    overflow: auto;
    margin-left: -2.5px;
  }

  ${theme.above.md} {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const CategoryGrid = ({ children }) => {
  const featuredCategories = children.map((child, index) => ({
    ...child?.props?.category?.value,
    key: index
  }));

  return (
    <StyledContainer>
      <ItemRow>
        {featuredCategories &&
          featuredCategories.map(cat => (
            <CategoryGridItem key={cat.key} cat={cat} />
          ))}
      </ItemRow>
    </StyledContainer>
  );
};

export default CategoryGrid;
