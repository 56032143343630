import React from 'react';
import MainContent from '../Inspiration/Single/MainContent';

/*==============================================================================
  # Styles
==============================================================================*/

/*==============================================================================
  # Component
==============================================================================*/

const ContentPage = ({ result }) => {
  const { name, mainHeader, breadcrumbText, images, content } = result;
  const title = !!mainHeader ? mainHeader : name;
  const image =
    images && images.length > 0
      ? {
          src: images[images.length - 1].url,
          alt: mainHeader
        }
      : null;
  const breadcrumbs = [
    {
      title: breadcrumbText
    }
  ];

  return (
    <MainContent
      breadcrumbs={breadcrumbs}
      title={title}
      image={image}
      content={content}
    />
  );
};

export default ContentPage;
