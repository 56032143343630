import React from 'react';
import { styled } from 'linaria/react';
import { Hero } from '../../ui/Headings';
import Image from '@jetshop/ui/Image/Image';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { Col } from '../../Layout/Container';
import { theme } from '../../Theme';

const Item = styled(Col)`
  padding: 2.5px;

  ${theme.below.xs} {
    width: 90vw;
    max-width: 90vw;
    min-width: 90vw;
  }

  ${theme.only.xs} {
    width: 45vw;
    max-width: 45vw;
    min-width: 45vw;
  }

  ${theme.only.sm} {
    width: 30vw;
    max-width: 30vw;
    min-width: 30vw;
    padding: 2.5px;
  }

  ${theme.above.md} {
    width: 33.33%;
    max-width: 33.33%;
    min-width: 33.33%;
    margin-bottom: 30px;
    padding: 2.5px;
  }
`;

const ItemInner = styled('div')`
  position: relative;
  width: 100%;
  padding-bottom: 83%;
  overflow: hidden;

  &:hover {
    [data-flight-image-container] {
      transform: scale(1.05);
      transition: all 5000ms ${theme.easings.primary} 100ms;

      &::after {
        background-color: ${theme.colors.grey};
        transition: all 5000ms ${theme.easings.primary} 100ms;
      }
    }
  }
`;

const ItemContent = styled('div')`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
`;

const ItemTitle = styled(Hero)`
  display: block;
  padding: 10px;
  margin-top: auto;
  margin-bottom: 30px;
  color: ${theme.colors.slategrey};
  background-color: rgba(255, 255, 255, 0.5);
  text-align: center;

  ${theme.below.md} {
    ${theme.fontSizes.mega}
  }
`;

const Background = styled(Image)`
  position: absolute !important;
  transform-origin: center center;
  transition: all 1000ms ${theme.easings.primary};

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${theme.colors.black};
    transition: all 1000ms ${theme.easings.primary};
    opacity: 0.25;
  }
`;

const CategoryCard = ({ cat }) => {
  if (!(cat.name && cat.images)) {
    return null;
  }
  return (
    <Item>
      <CategoryLink category={cat}>
        <ItemInner>
          {cat.images.length > 0 && (
            <Background
              src={cat.images[2].url}
              alt={cat.name}
              sizes={[1, 1 / 2, 1 / 3, 1 / 3, 1 / 3]}
              fillAvailableSpace={true}
            />
          )}

          <ItemContent>
            <ItemTitle>{cat.name}</ItemTitle>
          </ItemContent>
        </ItemInner>
      </CategoryLink>
    </Item>
  );
};

export default CategoryCard;
