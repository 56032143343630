import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-apollo';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import ContentPage from './ContentPage';
import { ContentEditorPage } from '../StartPage/StartPage';
import StartPageById from '../StartPage/StartPageById';
import pageContentQuery from '../PageContentQuery.gql';
import { HTML2JSON } from '../utility/functions';

const OfferPage = ({ path }) => {
  const {
    siteConfig: { configPageId }
  } = useShopConfig();

  const resConfig = useQuery(pageContentQuery, {
    variables: {
      id: configPageId
    }
  });

  if (
    resConfig &&
    resConfig.data &&
    resConfig.data.page &&
    resConfig.data.page.content
  ) {
    const json = HTML2JSON(resConfig.data.page.content);
    return <StartPageById startPageId={json.campaignPage} path={path} />;
  }

  return <StartPageById startPageId={62} path={path} />; // fallback in case of no campaign page config
};

const ContentPageHandler = ({ result, page, renderAsStartPage, path }) => {
  const location = useLocation();
  // if we have contenteditor items we want to render those, not the wysiwyg text
  // from the old way of creating pages
  if (page?.data?.items?.length > 0) {
    return <ContentEditorPage result={page} path={location.pathname} />;
  }

  if (path === '/erbjudanden') return <OfferPage path={path} />;

  const object = result?.data?.route?.object || page;
  if (!object) return null;
  return (
    <Fragment>
      <Helmet>
        <meta property="og:title" content={object?.head?.title} />
        <meta
          property="og:description"
          content={object?.head?.metaTags[0]?.content}
        />
        <meta name="description" content={object?.head?.metaTags[0]?.content} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={object?.images[object?.images.length - 2]?.url}
        />
      </Helmet>
      <ContentPage result={object} />
    </Fragment>
  );
};

export default ContentPageHandler;
