import React, { Fragment, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Helmet } from 'react-helmet-async';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import Analytics from '@jetshop/core/analytics/Analytics';
import { trackPageEvent } from '@jetshop/core/analytics/tracking';
import { StartPageContent } from './StartPageContent';
import LoadingPage from '../LoadingPage';
import routeQuery from '../RouteQuery.gql';

export const ContentEditorPage = ({ result: object, path }) => {
  const { selectedChannel } = useContext(ChannelContext);

  return (
    <Fragment>
      <Analytics
        event={trackPageEvent({
          title: 'Svenska Hem',
          pathname: path
        })}
      />
      <Helmet>
        <title>{object?.head?.title}</title>
        <meta property="og:title" content={object?.head?.title} />
        <meta
          property="og:description"
          content={object?.head?.metaTags?.[0]?.content}
        />
        <meta
          name="description"
          content={object?.head?.metaTags?.[0]?.content}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={selectedChannel.url} />
      </Helmet>

      <StartPageContent items={object?.data?.items} />
    </Fragment>
  );
};

const StartPage = ({ path = '/' }) => {
  const result = useQuery(routeQuery, {
    variables: {
      path: path
    }
  });

  if (result.loading) return <LoadingPage />;
  if (!result.data) return null;

  return (
    <ContentEditorPage
      result={result.data.route?.object}
      path={path}
    ></ContentEditorPage>
  );
};

export default StartPage;
