import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import Container from '../../Layout/Container';
import NewsletterField from '../../Cart/Newsletter/NewsletterField';
import { theme } from '../../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const Description = styled('p')`
  margin-top: 10px;
  font-style: italic;
  color: ${theme.colors.slategrey};
  ${theme.fontSizes.description}
`;

/*==============================================================================
  # Component
==============================================================================*/

const Newsletter = ({ id, marginTop, title, description, terms }) => {
  title = title?.value?.value || t('Prenumerera på vårt nyhetsbrev');
  return (
    <Container marginTop>
      <div>
        {title && (
          <label htmlFor={`newsletter-${id}`} className="heading-size">
            {title}
          </label>
        )}
      </div>

      <NewsletterField
        inputId={`newsletter-${id}`}
        large={false}
        confirmText={terms?.value?.value}
      />

      {description?.value?.value && (
        <Description>{description?.value?.value}</Description>
      )}
    </Container>
  );
};

export default Newsletter;
