import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import Container, { Col } from '../../Layout/Container';
import { SuperMega, Hero } from '../../ui/Headings';
import Text from '../../ui/Text';
import BrandLogo from '../../ui/BrandLogo';
import { ButtonLink } from '../../ui/Button';
import { theme } from '../../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const StyledRow = styled('div')`
  ${theme.below.lg} {
    grid-template-columns: auto;
  }
  display: grid;
  grid-template-columns: auto 40%;
  position: relative;
  margin: 0 !important;
  color: ${theme.colors.black};
  text-align: center;
  overflow: hidden;

  p {
    color: ${theme.colors.black};
  }

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${theme.colors.bggrey};
  }
`;

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${theme.colors.slategrey};

  ${theme.below.md} {
    text-align: center;
  }
`;

const ImageCol = styled(StyledCol)`
  padding-left: 0px !important;
  padding-right: 0px !important;

  ${theme.above.md} {
    min-height: 80vh;

    &.image-1 {
      order: 1;
    }

    &.image-2 {
      order: 2;
    }
  }

  .image-container {
    position: relative;
    width: 100%;
    height: 400px;

    ${theme.above.md} {
      position: absolute;
      top: 0px;
      height: 100%;
      width: 100%;
    }
  }
`;

const StyledImage = styled(Image)`
  width: 100% !important;
  height: 100% !important;
  padding: 0px !important;

  img {
    object-fit: cover !important;
    font-family: 'object-fit: cover' !important;
  }
`;

const ContentCol = styled(StyledCol)`
  ${theme.above.md} {
    &.image-1 {
      order: 2;
      padding-right: 0px;
    }

    &.image-2 {
      order: 1;
      padding-left: 0px;
    }
  }

  .content-inner {
    max-width: 540px;
    margin: 0 auto;
    padding: 30px 20px 40px;

    ${theme.above.md} {
      padding: 60px 30px 60px;
    }

    ${theme.above.lg} {
      padding: 100px 45px;
    }

    ${theme.above.xl} {
      padding: 150px 45px;
    }

    @media (min-width: 1600px) {
      padding: 150px 60px;
    }
  }

  .title,
  .sub-title,
  .text {
    margin-top: 0px;
    margin-bottom: 15px;

    ${theme.above.lg} {
      margin-bottom: 25px;
    }
  }

  .text {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .link-container {
    margin-top: 30px;
    text-align: center;

    ${theme.above.lg} {
      margin-top: 40px;
    }

    a {
      width: auto;
      height: auto;
      padding: 0px;
      text-decoration: none;
    }
  }
`;

const StyledText = styled(Text)`
  display: inline-block;
  width: 100%;
  margin: 20px auto 25px;

  ${theme.above.md} {
    margin-bottom: 30px auto 35px;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const HeroText = ({ children, logo, subTitle, text, title }) => {
  const image = children[0]?.props;
  const link = children[1]?.props;

  const imageSrc = image?.image?.value?.value;
  const imageAlt = image?.imageAlt?.value?.value;

  const linkTitle = link && link?.['linkTitle']?.value?.value;
  const linkUrl = link && link?.['linkUrl']?.value?.value;

  return (
    <Container fullWidth className="hero-text">
      <StyledRow>
        <ImageCol className="image-col image-1">
          <div className="image-container">
            {image && imageSrc && (
              <StyledImage
                alt={imageAlt || title.value.value}
                src={imageSrc}
                quality={80}
                sizes={[1, 1 / 2, 1500]}
                aspect="4:2"
                critical
              />
            )}
          </div>
        </ImageCol>
        <ContentCol className="image-1">
          <div className="content-inner">
            {logo?.value && (
              <BrandLogo
                style={{ margin: '0px auto 20px' }}
                width={120}
                src={logo.value.value}
              />
            )}
            <SuperMega className="title">{title.value.value}</SuperMega>
            {subTitle.value && (
              <Hero className="sub-title">{subTitle.value.value}</Hero>
            )}
            {text.value && (
              <StyledText className="text" content={text.value.value} />
            )}
            {linkUrl && linkTitle && (
              <div className="link-container">
                <ButtonLink to={linkUrl}>{linkTitle}</ButtonLink>
              </div>
            )}
          </div>
        </ContentCol>
      </StyledRow>
    </Container>
  );
};

export default HeroText;
