import React, { useRef, useState, Fragment } from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import useLogError from '@jetshop/core/hooks/useLogError';
import { CountriesInput } from '@jetshop/ui/Auth/FormFields/CountriesInput';
import { useCustomerQuery } from '@jetshop/core/components/Auth/useCustomer';
import { CustomerUpdateFormProvider } from '@jetshop/core/components/Auth/CustomerUpdateForm';
import { GlobalError } from '../Forms/GlobalError';
import InputWithLabel, { Label, Wrapper } from '../Forms/Input';
import LoadingPage from '../LoadingPage';
import BaseButton from '../ui/Button';
import { SubHeading } from './MyPagesStartPage';
import customerProfileQuery from './customerProfileQuery.gql';
import { DeleteAccount } from './DeleteAccount';
import { Newsletter } from './Newsletter';
import { ProfileView } from './ProfileView';
import { smallSection } from '../Auth/UI/Form';
import { theme } from '../Theme';
import ProfileDeliveryAddresses from './ProfileDeliveryAddresses';

/*==============================================================================
  # Styles
==============================================================================*/

export const BlockTitle = styled(SubHeading)``;

export const ButtonContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${theme.below.sm} {
    flex-direction: column;
  }
`;

export const PrimaryButton = styled(BaseButton)`
  ${theme.below.sm} {
    margin-bottom: 1rem;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const ProfileEdit = ({ setEditing }) => {
  return (
    <Fragment>
      <CustomerUpdateFormProvider
        customerProfileQuery={customerProfileQuery}
        onSubmitSuccess={() => setEditing(false)}
        render={({
          values,
          isSubmitting,
          billingAddressFields,
          handleChange,
          setCountryCode,
          isValid,
          status,
          countries
        }) => {
          return (
            <section className={smallSection}>
              <Wrapper>
                <Label
                  className={isSubmitting ? 'disabled' : ''}
                  htmlFor={'billingAddress.countryCode'}
                >
                  <span>{t('Country')}</span>
                </Label>
                <CountriesInput
                  name="billingAddress.countryCode"
                  label={t('billingAddress.countryCode')}
                  disabled={isSubmitting}
                  countries={countries}
                  onChange={e => {
                    setCountryCode(e.target.value);
                  }}
                />
              </Wrapper>
              {billingAddressFields.map(({ inputProps }) => {
                return (
                  <InputWithLabel {...inputProps} disabled={isSubmitting} />
                );
              })}

              {status && status.globalError && (
                <GlobalError style={{ marginBottom: '2em' }}>
                  {status.globalError}
                </GlobalError>
              )}

              <ButtonContainer>
                <PrimaryButton
                  aria-label="Cancel"
                  dark={true}
                  onClick={() => setEditing(false)}
                >
                  {t('Cancel')}
                </PrimaryButton>

                <PrimaryButton
                  type="submit"
                  dark={true}
                  aria-label="Save"
                  disabled={isSubmitting || !isValid}
                >
                  {t('Save')}
                </PrimaryButton>
              </ButtonContainer>
            </section>
          );
        }}
      />
    </Fragment>
  );
};

const InlineApolloErrorHandler = ({ refetch, error }) => {
  useLogError(true, error, 'InlineErrorHandler');

  return (
    <div>
      <p>{t('Something went wrong.')}</p>
      <br />
      <p>
        <PrimaryButton
          aria-label="Try again"
          ark={true}
          onClick={() => refetch()}
        >
          {t('Try again')}
        </PrimaryButton>
      </p>
    </div>
  );
};

const Profile = props => {
  const [isEditing, setEditingState] = useState(false);

  const profileBlockRef = useRef(null);

  function setEditing(nextState) {
    setEditingState(nextState);
    const $el = profileBlockRef.current;
    if ($el && $el.scrollIntoView) {
      $el.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  return (
    <div className="block" id="profile" ref={profileBlockRef}>
      <BlockTitle>{t('My profile')}</BlockTitle>
      {isEditing ? (
        <ProfileEdit setEditing={setEditing} />
      ) : (
        <ProfileView setEditing={setEditing} />
      )}
    </div>
  );
};

function ProfilePage() {
  const { loading, error, refetch } = useCustomerQuery({
    query: customerProfileQuery
  });

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <InlineApolloErrorHandler {...{ error, refetch }} />;
  }

  return (
    <div className="main-wrapper">
      <Profile />
      <ProfileDeliveryAddresses />
      <Newsletter />
      <DeleteAccount />
    </div>
  );
}

export default ProfilePage;
