import React, { Fragment } from 'react';
import { styled } from 'linaria/react';
import Container from '../../Layout/Container';
import { Mega } from '../../ui/Headings';
import Text from '../../ui/Text';
import Link from '../../utility/Link';
import { theme } from '../../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const Plate = styled(Container)`
  color: ${theme.colors.slategrey};
  background-color: ${theme.colors.bggrey};
  padding: 30px;

  ${theme.above.md} {
    padding: 60px 80px;
  }

  ${theme.above.xl} {
    padding: 80px 100px;
  }

  .title {
    margin-bottom: 30px;
  }
`;

const StyledText = styled(Text)`
  ${theme.fontSizes.description}
`;

const MockLink = styled('div')`
  margin-top: 30px;
  text-align: right;
  font-family: ${theme.fonts.heading};
  ${theme.fontSizes.heading}
  text-transform: none;
`;

/*==============================================================================
  # Component
==============================================================================*/

const LinkWrapper = ({ to, children, ...rest }) =>
  to ? (
    <Link to={to} {...rest} style={{ textDecoration: 'none' }}>
      {children}
    </Link>
  ) : (
    <Fragment>{children}</Fragment>
  );

const TextPlate = ({ id, marginTop, title, text, children }) => {
  const link = children?.[0]?.props;
  const linkTitle = link?.linkTitle?.value?.value;
  const linkUrl = link?.linkUrl?.value?.value;

  return (
    <Container id={id} marginTop={!!marginTop?.value?.value}>
      <LinkWrapper to={linkUrl}>
        <Plate>
          {title?.value?.value && (
            <Mega className="title">{title?.value?.value}</Mega>
          )}
          {text?.value?.value && (
            <StyledText content={text?.value?.value} full={true} />
          )}
          {linkTitle && <MockLink className="mock-link">{linkTitle}</MockLink>}
        </Plate>
      </LinkWrapper>
    </Container>
  );
};

export default TextPlate;
