import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import t from '@jetshop/intl';
import React from 'react';
import { styled } from 'linaria/react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { ReactComponent as Box } from '../../svg/Box.svg';
import { ReactComponent as Lock } from '../../svg/Lock.svg';
import { ReactComponent as Person } from '../../svg/Person.svg';
import { ResultContainer } from '../Inspiration/InspirationOverview';
import ChangePassword from './ChangePasswordPage';
import MyPagesSidebar from './MyPagesSidebar';
import MyPagesStartPage from './MyPagesStartPage';
import OrderListPage from './Orders/OrderListPage';
import ProfilePage from './ProfilePage';
import { useLocation } from 'react-router';
import { theme } from '../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const MyPagesContainer = styled('div')`
  display: flex;
  flex-direction: row;

  ${theme.below.sm} {
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
  }

  .main-wrapper {
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;

    ${theme.below.sm} {
      padding-left: 0rem;
      padding-right: 0rem;
    }

    .block {
      background: white;
      padding: 1.5rem;

      + .block {
        margin-top: 1.5rem;
      }

      .radio-container {
        margin: 0.5rem 0;
      }

      ul.row {
        display: flex;
        flex-direction: row;
      }
    }

    .half-block {
      width: calc(50% - 1.5rem);
      margin-left: 0.75rem;
      margin-right: 0.75rem;

      ${theme.above.md} {
        :first-child,
        :nth-child(2) {
          margin-top: 0;
        }
      }

      ${theme.below.md} {
        width: 100%;
      }
    }

    strong {
      font-weight: bold;
    }

    .profile-form {
      dt,
      dd {
        display: block;
        line-height: 1.5;
      }

      dd {
        margin-bottom: 1em;
      }

      dt {
        font-size: 0.75em;
      }

      dd {
        font-weight: 600;
      }
    }
  }
`;

export const PageRouteNameWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-right: 12px;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

export const baseRoute = {
  path: '/mina-sidor',
  component: MyPagesStartPage,
  exact: true,
  name: t('My Pages'),
  sideBarComponent: t('My Pages')
};

export const myPagesRoutes = [
  {
    path: '/mina-sidor/ordrar',
    component: OrderListPage,
    sideBarComponent: (
      <PageRouteNameWrapper>
        <Box />
        {t('My orders')}
      </PageRouteNameWrapper>
    ),
    name: t('My orders')
  },
  {
    path: '/mina-sidor/profil',
    component: ProfilePage,
    sideBarComponent: (
      <PageRouteNameWrapper>
        <Person />
        {t('My profile')}
      </PageRouteNameWrapper>
    ),
    name: t('My profile')
  },
  {
    path: '/mina-sidor/byt-losenord',
    component: ChangePassword,
    sideBarComponent: (
      <PageRouteNameWrapper>
        <Lock />
        {t('Change Password')}
      </PageRouteNameWrapper>
    ),
    name: t('Change Password')
  }
];

function MyPages() {
  const { loggedIn } = useAuth();
  const location = useLocation();
  const {
    routes: { login }
  } = useShopConfig();

  if (!loggedIn) {
    return (
      <Redirect
        to={{
          ...location,
          pathname: login.path
        }}
      />
    );
  }

  return (
    <ResultContainer>
      <MyPagesContainer>
        <MyPagesSidebar />

        <Route {...baseRoute} />
        <Switch>
          {myPagesRoutes.map(route => (
            <Route key={route.path} {...route} />
          ))}
        </Switch>
      </MyPagesContainer>
    </ResultContainer>
  );
}

export default MyPages;
