import React from 'react';
import { styled } from 'linaria/react';
import Container, { Row } from '../../Layout/Container';
import { theme } from '../../Theme';
import ImagePod from './ImagePod';

/*==============================================================================
  # Styles
==============================================================================*/

const StyledContainer = styled(Container)`
  overflow-x: hidden;
  margin: 30px auto;
`;

const ItemRow = styled(Row)`
  margin-left: -2.5px;

  ${theme.below.md} {
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow: auto;
    margin-left: -2.5px;
  }

  ${theme.above.md} {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
`;

export default function Podgrid({ children }) {
  const imagePods = children.map(imagepod => imagepod.props);

  return (
    <StyledContainer>
      <ItemRow>
        {imagePods &&
          imagePods.map((imagepod, i) => (
            <ImagePod
              key={`imagepod-${i}`}
              image={imagepod.image}
              overline={imagepod.overline}
              description={imagepod.description}
              linkurl={imagepod.linkurl}
            />
          ))}
      </ItemRow>
    </StyledContainer>
  );
}
