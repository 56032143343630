import React, { PureComponent, Fragment } from 'react';
import { Query } from 'react-apollo';
import { kebabCase } from 'lodash';
import { ResultContainer } from '../Inspiration/InspirationOverview';
import StoreDetail from './StoreDetail';
import LoadingPage from '../LoadingPage';
import StoreQuery from './StoreQuery.gql';

export default class Store extends PureComponent {
  render() {
    const {
      match: {
        params: { id: storeId }
      }
    } = this.props;

    if (!storeId) return null;

    return (
      <ResultContainer>
        <Query query={StoreQuery} variables={{ id: storeId }}>
          {({ data, loading, error }) => {
            if (loading) return <LoadingPage />;
            if (!data) return null;

            const { stores } = data;
            let currentStore = null;

            if (stores) {
              stores.forEach(store => {
                const kebabName = kebabCase(store.name);
                if (kebabName === storeId || store.id === storeId) {
                  currentStore = store;
                }
              });
            }

            return (
              <Fragment>
                {!currentStore ? (
                  'Hittade ingen butik!'
                ) : (
                  <StoreDetail store={currentStore} />
                )}
              </Fragment>
            );
          }}
        </Query>
      </ResultContainer>
    );
  }
}
