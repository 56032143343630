import React from 'react';
import { styled } from 'linaria/react';
import Container from '../../Layout/Container';
import { theme } from '../../Theme';

export const BlocksContext = React.createContext({ desktopAspect: '3:2' });

/*==============================================================================
  # Styles
==============================================================================*/

const StyledContainer = styled(Container)`
  max-width: 1440px !important;
`;

const StyledRow = styled('div')`
  display: grid;
  gap: 4px;
  position: relative;

  &.blocks-2 {
    height: 720px;
    grid-template-columns: 50fr 50fr;
    grid-template-areas: 'block1 block2';
  }

  &.blocks-3 {
    height: 640px;
    grid-template-columns: 67fr 33fr;
    grid-template-areas:
      'block1 block2'
      'block1 block2';
  }

  &.blocks-4 {
    height: 720px;
    grid-template-columns: 50fr 50fr;
    grid-template-areas:
      'block1 block2'
      'block3 block4';
  }

  & > *:nth-child(1) {
    grid-area: block1;
  }

  & > *:nth-child(2) {
    grid-area: block2;
  }

  & > *:nth-child(3) {
    grid-area: block3;
  }

  & > *:nth-child(4) {
    grid-area: block4;
  }

  .blocks-3-right {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .blocks-3-right > *:nth-child(1),
  .blocks-3-right > *:nth-child(2) {
    flex: 1;
  }

  @media (max-width: 1440px) {
    &.blocks-2,
    &.blocks-4 {
      height: 50vw;
    }
    &.blocks-3 {
      height: 44.54vw;
    }
  }

  ${theme.below.md} {
    gap: 2px;
    display: flex;
    flex-direction: column;
    height: auto !important;
    .blocks-3-right {
      gap: 2px;
    }
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const HeroBlocks = ({ children }) => {
  const blocksQty = children.length;
  const desktopAspect =
    blocksQty === 2 ? '1:1' : blocksQty === 4 ? '2:1' : '3:2';
  return (
    <StyledContainer className="hero-blocks useFullWidth">
      <StyledRow className={`blocks-${blocksQty}`}>
        <BlocksContext.Provider value={{ desktopAspect }}>
          {children.length === 3 ? (
            <>
              {children[0]}
              <div className="blocks-3-right">
                {children[1]}
                {children[2]}
              </div>
            </>
          ) : (
            children
          )}
        </BlocksContext.Provider>
      </StyledRow>
    </StyledContainer>
  );
};

export default HeroBlocks;
