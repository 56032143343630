import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  Fragment,
  useCallback
} from 'react';
import { styled } from 'linaria/react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import throwErrorInDev from '@jetshop/core/helpers/throwErrorInDev';
import { LocationStateContext } from './StoreLocator';
import { useMapState } from './useMapState';
import StoreLink, { StoreLinkContent } from './StoreLink';
import { theme } from '../Theme';
import IconMarker from '../../svg/IconMarker.svg';
import ActiveIconMarker from '../../svg/ActiveIconMarker.svg';
import { ReactComponent as CrossIcon } from '../../svg/Cross.svg';

/*==============================================================================
  # Styles
==============================================================================*/

const Wrapper = styled('div')`
  flex: 0 0 60%;
  height: 100%;
  padding: 0 14px;

  .map-container {
    min-height: 100%;
    height: 30vh;

    ${theme.above.lg} {
      height: 100%;
    }
  }

  .gm-style-moc {
    height: unset !important;
  }
`;

const InfoWindowContent = styled('div')`
  position: absolute;
  bottom: 0;
  width: 80%;
  background: #fff;
  padding: 6px 10px 2px;
  line-height: 1.5;
  font-family: ${theme.fonts.primary};

  strong {
    font-size: 14px;
    text-transform: uppercase;
    font-family: ${theme.fonts.heading};
  }

  div {
    font-size: 12px;
  }

  button {
    position: absolute;
    top: 10px;
    right: 20px;
  }

  ${theme.above.md} {
    width: 80%;
    padding: 16px 20px;

    strong {
      font-size: 16px;
    }

    div {
      font-size: 14px;
    }
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const LoadingElement = () => <div style={{ height: '100%' }}></div>;

const StoreMap = ({
  stores,
  userLocation,
  closestStore,
  search,
  setSearch
}) => {
  const ref = useRef();
  const locationState = useContext(LocationStateContext);
  const { isOpen, infoId, showInfo } = useMapState();
  const [mapsLoaded, setMapsLoaded] = useState(false);
  const [hoveredLocation, setHoveredLocation] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const { googleMapsApiKey } = useShopConfig();
  const defaultLocation = { lat: 61.339463, lng: 15.449905 };

  useEffect(() => {
    if (infoId) setSelectedStore(stores.find(store => store.id === infoId));
  }, [infoId, stores]);

  throwErrorInDev(
    typeof googleMapsApiKey === 'undefined',
    'Make sure googleMapsApiKey is defined in your shop.config.js. See https://developers.google.com/maps/documentation/javascript/get-api-key'
  );

  const onMarkerClick = useCallback(
    id => {
      const selectedStoreName = stores.find(store => store.id === id).name;
      if (selectedStoreName) {
        setSearch(selectedStoreName.replace(/(<([^>]+)>)/gi, ''));
      }
      showInfo(id);
    },
    [stores, showInfo, setSearch]
  );

  const setDefaultZoom = useCallback(() => {
    if (ref.current) {
      if (stores.length === 0) {
        ref.current.setCenter({ lat: 61.339463, lng: 15.449905 });
        ref.current.setZoom(5);
      } else {
        // Stores are loaded, fit bounds
        const bounds = new window.google.maps.LatLngBounds();
        stores.map(store => {
          if (store.coordinates) {
            bounds.extend(
              new window.google.maps.LatLng(
                store.coordinates.latitude,
                store.coordinates.longitude
              )
            );
          }
          return null;
        });

        // Don't zoom in too far on only one marker
        if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
          const extendPoint1 = new window.google.maps.LatLng(
            bounds.getNorthEast().lat() + 0.015,
            bounds.getNorthEast().lng() + 0.015
          );
          const extendPoint2 = new window.google.maps.LatLng(
            bounds.getNorthEast().lat() - 0.015,
            bounds.getNorthEast().lng() - 0.015
          );
          bounds.extend(extendPoint1);
          bounds.extend(extendPoint2);
        }

        ref.current.fitBounds(bounds);
      }
    }
  }, [stores]);

  // Sets zoom to show all stores when stores are loaded
  useEffect(() => {
    if (stores && mapsLoaded) {
      setDefaultZoom();
    }
  }, [mapsLoaded, stores, setDefaultZoom]);

  // Zooms to store when hovering over a store in the list
  useEffect(() => {
    if (mapsLoaded) {
      if (locationState.activeLocation && search === '') {
        const activeStore = stores.find(
          store => store.id === locationState.activeLocation
        );
        if (activeStore && activeStore.coordinates) {
          ref.current.setCenter({
            lat: activeStore.coordinates.latitude,
            lng: activeStore.coordinates.longitude
          });
          ref.current.setZoom(6);
        }
      } else {
        setDefaultZoom();
      }
    }
  }, [
    mapsLoaded,
    locationState.activeLocation,
    stores,
    search,
    setDefaultZoom
  ]);

  useEffect(() => {
    if (closestStore && mapsLoaded) {
      // User has shared its location and the closest store has been calculated
      const bounds = new window.google.maps.LatLngBounds();

      bounds.extend(
        new window.google.maps.LatLng(
          userLocation.latitude,
          userLocation.longitude
        )
      );
      bounds.extend(
        new window.google.maps.LatLng(
          closestStore.coordinates.latitude,
          closestStore.coordinates.longitude
        )
      );

      ref.current.fitBounds(bounds);
    }
  }, [userLocation, closestStore, mapsLoaded]);

  const closeInfoWindow = () => {
    showInfo(null);
    setSelectedStore(null);
    setSearch('');
  };

  return (
    <Wrapper>
      <LoadScript
        loadingElement={<LoadingElement />}
        preventGoogleFontsLoading={true}
        googleMapsApiKey={googleMapsApiKey}
        language="sv"
      >
        <GoogleMap
          defaultZoom={6}
          mapContainerClassName="map-container"
          defaultCenter={defaultLocation}
          options={{
            streetViewControl: false,
            mapTypeControl: false
          }}
          onLoad={map => {
            ref.current = map;
            setMapsLoaded(true);
          }}
        >
          <Fragment>
            {userLocation && userLocation.latitude && userLocation.longitude && (
              <Marker
                position={{
                  lat: userLocation.latitude,
                  lng: userLocation.longitude
                }}
              />
            )}

            {mapsLoaded &&
              stores.map((store, index) => {
                const isActive =
                  store.id === locationState.activeLocation ||
                  store.id === hoveredLocation;

                return store.coordinates ? (
                  <Fragment key={store.id}>
                    <Marker
                      icon={{
                        url: isActive ? ActiveIconMarker : IconMarker,
                        anchor: new window.google.maps.Point(16, 32),
                        scale: isActive ? 1.25 : 1.2,
                        labelOrigin: new window.google.maps.Point(12, 14)
                      }}
                      zIndex={isActive ? 99999 : index}
                      position={{
                        lat: store.coordinates.latitude,
                        lng: store.coordinates.longitude
                      }}
                      onMouseOver={() => {
                        setHoveredLocation(store.id);
                      }}
                      onMouseOut={() => setHoveredLocation(null)}
                      onClick={() => onMarkerClick(store.id)}
                    />
                  </Fragment>
                ) : null;
              })}
          </Fragment>
          {mapsLoaded && isOpen && selectedStore ? (
            <InfoWindowContent>
              <strong
                dangerouslySetInnerHTML={{
                  __html: selectedStore.name
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: selectedStore.address1
                }}
              />
              <StoreLink storeName={selectedStore.name}>
                <StoreLinkContent />
              </StoreLink>
              <button
                type="button"
                aria-label="Close popup"
                onClick={closeInfoWindow}
              >
                <CrossIcon />
              </button>
            </InfoWindowContent>
          ) : null}
        </GoogleMap>
      </LoadScript>
    </Wrapper>
  );
};

export default StoreMap;
