import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'linaria/react';
import { kebabCase } from 'lodash';
import { ReactComponent as CarrotSvg } from '@jetshop/ui/svg/Carrot.svg';
import t from '@jetshop/intl';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { theme } from '../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const StyledLink = styled(Link)`
  font-size: 1rem;
  letter-spacing: 0.5px;

  ${theme.above.md} {
    font-size: 1.25rem;
  }
`;

const LinkContent = styled('span')`
  display: inline-flex;
  align-items: center;
  padding: 3px 0px;
  color: ${theme.colors.mediumdarkgrey} !important;
  ${theme.fontSizes.description}
  text-decoration: none;
  line-height: 1;

  svg {
    position: relative;
    height: 5px;
    margin-left: 3px;
    transform: rotate(-90deg);
    color: inherit;

    path {
      stroke: currentColor;
    }
  }

  &:hover {
    text-decoration: underline;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

export const StoreLinkContent = () => (
  <LinkContent>
    {t('Mer information')}
    <CarrotSvg />
  </LinkContent>
);

const StoreLink = ({ storeName, children }) => {
  const {
    routes: { stores }
  } = useShopConfig();
  if (!storeName) return;

  //Stip html to remove highlight in storename
  storeName = storeName.replace(/<[^>]*>?/gm, '').trim();

  let kebabCaseName = kebabCase(storeName);
  return (
    <StyledLink
      to={`${stores.path}/${kebabCaseName}`}
      style={{ textDecoration: 'none' }}
    >
      {children}
    </StyledLink>
  );
};

export default StoreLink;
