import React, { Fragment } from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import Head from '@jetshop/core/components/Head';
import useCustomerOrdersQuery from '@jetshop/core/hooks/useCustomerOrdersQuery';
import { SubHeading, Line } from '../MyPagesStartPage';
import { theme } from '../../Theme';
import { ListOrder } from './ListOrder';
import CustomerOrders from './CustomerOrders.gql';

/*==============================================================================
  # Styles
==============================================================================*/

const OrderListPageWrapper = styled('div')`
  &.main-wrapper {
    ${theme.below.sm} {
      margin-top: 12px;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

export default function OrderListPage() {
  const { orders, loading, totalOrders } = useCustomerOrdersQuery({
    query: CustomerOrders
  });

  if (loading) {
    return (
      <OrderListPageWrapper className="main-wrapper">
        <Heading />
        <ListOrder placeholder={true} />
      </OrderListPageWrapper>
    );
  }

  if (!loading && orders.length === 0) {
    return (
      <OrderListPageWrapper className="main-wrapper">
        <Heading />
        <Line>{t('No orders found')}</Line>
        <ListOrder placeholder={true} className="empty" />
      </OrderListPageWrapper>
    );
  }

  return (
    <OrderListPageWrapper className="main-wrapper">
      <Heading totalOrders={totalOrders} />
      {orders.map((order, idx) => (
        <ListOrder
          order={order}
          key={order.id}
          index={orders.length - idx}
          style={{ marginTop: idx > 0 ? '2em' : 0 }}
        />
      ))}
    </OrderListPageWrapper>
  );
}

function Heading({ totalOrders }) {
  return (
    <Fragment>
      <Head data={{ title: 'My orders', metaTags: [] }} />
      <SubHeading size="h1">
        {t('My Orders')} {totalOrders && <Fragment>({totalOrders})</Fragment>}
      </SubHeading>
    </Fragment>
  );
}
