import React from 'react';
import { styled } from 'linaria/react';
import Container from '../../Layout/Container';
import { SuperMega, Hero } from '../../ui/Headings';
import { ButtonLink } from '../../ui/Button';
import { theme } from '../../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const CtaContainer = styled(Container)`
  max-width: initial;
  padding: 0;
  margin-top: 30px;
  text-align: center;
  color: ${theme.colors.slategrey};

  .inner {
    width: 100%;
  }

  .title {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      position: relative;
      display: block;
      padding: 0 0.3em;
      background: ${theme.colors.white};
      z-index: 2;
    }

    &::before {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      height: 2px;
      background-color: ${theme.colors.mediumdarkgrey};
      width: 100%;
      z-index: 1;
    }
  }

  .sub-title {
    margin-bottom: 10px;
  }
`;

const CTAButton = styled(ButtonLink)`
  margin-top: 20px;
`;

/*==============================================================================
  # Component
==============================================================================*/
const getValue = prop => prop?.value?.value;
const TextImage = ({ title, text, linkTitle, linkUrl }) => {
  title = getValue(title);
  text = getValue(text);
  linkTitle = getValue(linkTitle);
  linkUrl = getValue(linkUrl);

  const link = { to: linkUrl, alt: linkTitle };
  const titleTag = 'h1';

  return (
    <CtaContainer marginTop={false}>
      <div className="inner">
        <SuperMega className="title" size={titleTag}>
          <span>{title}</span>
        </SuperMega>
        {text && <Hero className="sub-title">{text}</Hero>}
        {linkUrl && linkTitle && <CTAButton {...link}>{linkTitle}</CTAButton>}
      </div>
    </CtaContainer>
  );
};

export default TextImage;
