import React, { Component } from 'react';
import { styled } from 'linaria/react';
import Slider from 'react-slick';
import Container from '../../Layout/Container';
import ProductGrid from '../../CategoryPage/ProductGrid';
import { ProductCard } from '../../CategoryPage/ProductCard';
import { Hero } from '../../ui/Headings';
import BorderHeading from '../../ui/BorderHeading';
import { Favourite } from '../../ProductList/Favourite';
import { theme } from '../../Theme';
import 'slick-carousel/slick/slick.css';

/*==============================================================================
  # Styles
==============================================================================*/

const StyledSlider = styled(Slider)`
  transition: all 250m ease;
  padding-left: 25px;
  padding-right: 25px;

  ${theme.above.md} {
    padding-left: 40px;
    padding-right: 40px;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    height: auto !important;

    > div {
      height: 100%;

      > div {
        height: 100%;
      }
    }
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    display: block;
    padding: 0;
    border: none;
    color: transparent;
    font-size: 0;
    line-height: 0;
    outline: none;
    cursor: pointer;
    transform: translate(0, -50%);

    width: 25px;
    height: 25px;
    background-color: transparent !important;
    box-shadow: none;
    z-index: 1;

    ${theme.above.md} {
      width: 40px;
      height: 40px;
    }

    &::before {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 25px;
      width: 25px;
      border-right: 2px solid ${theme.colors.slategrey};
      border-bottom: 2px solid ${theme.colors.slategrey};
      opacity: 1;
      transform-origin: top left;
      transform: rotate(135deg) translate(-50%, -50%);
    }

    &.slick-prev {
      left: 0;
    }

    &.slick-next {
      right: 0;

      &::before {
        transform-origin: top left;
        transform: rotate(-45deg) translate(-50%, -50%);
      }
    }
  }

  .product-card {
    padding-bottom: 7px;

    .inner-card:hover {
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.22);
    }
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

export class ProductCarousel extends Component {
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  state = {
    slidesToShow: 2,
    slidesToScroll: 1
  };

  handleResize = () => {
    let slidesToShow = 2;

    if (typeof window !== 'undefined') {
      if (window.matchMedia(theme.above.lg.replace('@media ', '')).matches) {
        slidesToShow = 4;
      } else if (
        window.matchMedia(theme.above.md.replace('@media ', '')).matches
      ) {
        slidesToShow = 3;
      }
    }

    if (slidesToShow !== this.state.slidesToShow) {
      this.setState({
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToShow > 1 ? slidesToShow - 1 : 1
      });
    }
  };

  render() {
    let { products, listName, ...rest } = this.props;
    let { slidesToShow, slidesToScroll } = this.state;

    if (!products || products.length <= 0) return null;

    let settings = {
      dots: false,
      infinite: true,
      speed: 500,
      easing: 'ease',
      slidesToShow: slidesToShow,
      slidesToScroll: slidesToScroll
    };

    if (slidesToShow > products.length) {
      return (
        <div>
          <ProductGrid
            listName="carousel-grid"
            products={products}
            ProductComponent={ProductCard}
          />
        </div>
      );
    }

    return (
      <StyledSlider {...settings} {...rest}>
        {products.filter(Boolean).map(product => {
          return (
            <ProductCard
              key={product.id}
              product={product}
              list={listName}
              as="div"
            >
              <Favourite product={product} />
            </ProductCard>
          );
        })}
      </StyledSlider>
    );
  }
}

const Products = ({ title, marginTop, children }) => {
  const products = children.map(product => product.props.product.value);

  return (
    <Container
      marginTop={marginTop.value ? Boolean(marginTop.value.value) : 'md'}
    >
      {title.value && (
        <BorderHeading align="center">
          <Hero size="h3">{title.value.value}</Hero>
        </BorderHeading>
      )}
      <ProductCarousel
        products={products}
        listName="Startpage product section"
      />
    </Container>
  );
};

export default Products;
