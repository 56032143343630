import React, { Component, Fragment } from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image';
import Container from '../Layout/Container';
import { Mega, Hero } from '../ui/Headings';
import EntryCard, { EntryContainer } from '../ui/EntryCard';
import Text from '../ui/Text';
import Button from '../ui/Button';
import Breadcrumbs from '../ui/Breadcrumbs';
import { theme } from '../Theme';
import prepareInspirationCards from './PrepareInspirationCards';

/*==============================================================================
  # Styles
==============================================================================*/

const HeaderImage = styled(Image)`
  width: 100%;

  > div {
    background: transparent !important;
    text-align: center;

    &::before {
      display: none;
    }
  }

  [data-flight-image-children] {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 350px;
    padding: 30px 15px 60px;
    color: ${theme.colors.slategrey};

    ${theme.above.md} {
      min-height: 400px;
    }

    .title {
      padding: 0px 25px 10px;
      border-bottom: 1px solid ${theme.colors.slategrey};
    }

    .sub-title {
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }
`;

export const ResultContainer = styled(Container)`
  padding-top: 30px;

  ${theme.above.md} {
    padding-top: 40px;
  }
`;

const SortContainer = styled('ul')`
  margin-bottom: 17.5px;

  ${theme.above.md} {
    margin-bottom: 27.5px;
  }
`;

const Item = styled('li')`
  display: inline-block;
  margin: 0px 10px 10px 0px;

  .sort-btn {
    padding: 0;
    text-transform: uppercase;

    &.current > div {
      color: ${theme.colors.white};
      background-color: ${theme.colors.black};
    }
  }
`;

const StyledEntryContainer = styled(EntryContainer)`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(3, 1fr);
  ${theme.below.md} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const StyledEntryCard = styled(EntryCard)`
  animation: fadeIn 500ms ease;
  @keyframes fadeIn {
    0%,
    10% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const NoneFound = styled('div')`
  width: 100%;
  margin-top: 30px;
  text-align: center;
`;

const LoadMoreBtn = styled(Button)`
  height: auto;
  padding: 0px;
  margin: 0 auto;
`;

/*==============================================================================
  # Component
==============================================================================*/

class SortItem extends Component {
  render() {
    const { current, catId, onClick, children } = this.props;
    return (
      <Item>
        <Button
          className={`sort-btn ${current ? ' current' : ''}`}
          onClick={() => onClick(catId)}
        >
          {children}
        </Button>
      </Item>
    );
  }
}

class InspirationOverview extends Component {
  componentDidMount() {
    let $_GET = {};
    if (document.location.toString().indexOf('?') !== -1) {
      const query = document.location
        .toString()
        // get the query string
        .replace(/^.*?\?/, '')
        // and remove any existing hash string (thanks, @vrijdenker)
        .replace(/#.*$/, '')
        .split('&');

      for (let i = 0, l = query.length; i < l; i++) {
        const aux = decodeURIComponent(query[i]).split('=');
        $_GET[aux[0]] = aux[1];
      }
    }

    if ($_GET['kategori']) {
      const categoryID = parseInt($_GET['kategori']);

      this.setState({
        currentCat: categoryID
      });
    }
  }

  state = {
    visible: 9,
    loadChunk: 9,
    currentCat: 'all'
  };

  setCategory = id => {
    const { loadChunk } = this.state;
    this.setState({
      visible: loadChunk,
      currentCat: id
    });
  };

  loadMore = () => {
    const { visible, loadChunk } = this.state;
    this.setState({
      visible: visible + loadChunk
    });
  };

  render() {
    let { name, title, text, images, rawCategories, inspoCatId } = this.props;
    const { currentCat, visible } = this.state;

    const preparedCards = prepareInspirationCards(rawCategories, inspoCatId);
    let { articles, articleCategories } = preparedCards;

    //Filter articles
    let filterdArticles = articles.filter((article, i) => {
      if (currentCat === 'all') {
        return true;
      } else if (currentCat === article.categoryID) {
        return true;
      } else {
        return false;
      }
    });

    //Breadcrumbs
    let breadcrumbs = [
      {
        title: 'Inspiration'
      }
    ];

    return (
      <Fragment>
        <Container marginTop={false}>
          <Breadcrumbs items={breadcrumbs} />
        </Container>
        {images && images.length > 0 && (
          <Container>
            <HeaderImage
              alt={title}
              src={images}
              sizes={[1, 1, 1, 1, 1200]}
              fillAvailableSpace
              cover
              aspect="3:1"
              crop={true}
            >
              <Mega className="title" size="h1">
                {name}
              </Mega>
              {title && title !== name && (
                <Hero className="sub-title" size="h2">
                  {title}
                </Hero>
              )}
              {text && <Text small content={text} />}
            </HeaderImage>
          </Container>
        )}

        <ResultContainer>
          <SortContainer>
            <SortItem
              catId="all"
              current={currentCat === 'all'}
              onClick={id => this.setCategory(id)}
            >
              {t('Alla')}
            </SortItem>

            {articleCategories &&
              Object.values(articleCategories).length > 0 &&
              Object.values(articleCategories).map((cat, i) => (
                <SortItem
                  key={i}
                  catId={cat.id}
                  current={currentCat === cat.id}
                  onClick={id => this.setCategory(id)}
                >
                  {cat.title}
                </SortItem>
              ))}
          </SortContainer>

          <StyledEntryContainer justify="space-between">
            {filterdArticles && filterdArticles.length > 0 ? (
              filterdArticles
                .filter((article, i) => i < visible)
                .map((article, i) => (
                  <StyledEntryCard
                    sizes={[2, 1 / 2, 1 / 3, 1 / 3, 1 / 3]}
                    key={i}
                    topTitle={article.topTitle}
                    title={article.title}
                    image={article.image}
                    link={{
                      title:
                        article.link && article.link.title
                          ? article.link.title
                          : '',
                      to:
                        article.link && article.link.to ? article.link.to : '/'
                    }}
                  />
                ))
            ) : (
              <NoneFound>{t('Inga artiklar hittades')}</NoneFound>
            )}
          </StyledEntryContainer>

          {filterdArticles.length > visible && (
            <Container marginTop={true}>
              <LoadMoreBtn
                aria-label="Fler artiklar"
                dark={true}
                onClick={() => this.loadMore()}
              >
                {t('Fler artiklar')}
              </LoadMoreBtn>
            </Container>
          )}
        </ResultContainer>
      </Fragment>
    );
  }
}

export default InspirationOverview;
