import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import UserLocation from './UserLocation';
import { LocationStateContext } from './StoreLocator';
import { Heading } from '../ui/Headings';
import { theme } from '../Theme';
import StoreLink, { StoreLinkContent } from './StoreLink';
import t from '@jetshop/intl';

/*==============================================================================
  # Styles
==============================================================================*/

const StoreListContainer = styled('div')`
  overflow-y: auto;
  padding: 0;

  .highlight {
    background: #e0e0e0;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

const NoResultsHeading = styled(Heading)`
  padding: 1rem 0;
  text-align: center;
  border-bottom: 1px solid ${theme.colors.grey};
`;

const StoreContainer = styled('li')`
  width: 100%;
  position: relative;
  background: ${theme.colors.white};
  padding: 16px 14px;
  border-bottom: 1px solid ${theme.colors.grey};
  font-family: ${theme.fonts.primary};

  ${theme.fontSizes.description}

  > div {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 10px;
    justify-content: space-between;
    align-items: start;
  }

  > div:first-child {
    margin-bottom: 6px;
  }

  > div:last-child {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;

    & > div:last-child {
      text-align: right;
    }
  }

  :hover {
    background: #f2f2f2;
  }

  :focus {
    outline: none;
    border: 1px solid ${theme.colors.black};
  }
`;

const ItemRow = styled('div')`
  display: flex;
`;

const ItemCol = styled('div')`
  position: relative;

  h3 {
    line-height: 1;
  }

  a {
    color: ${theme.colors.text};
    text-decoration: none;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const Store = ({ store: { id, name, address1, openHours, contact } }) => {
  const locationState = useContext(LocationStateContext);
  /**
   * @param {string} input
   * @returns {string | null}
   */
  function extractPhoneNumber(input) {
    const match = input.match(/Telefon:?\s*([\d\s-]+)/);
    if (match && match[1]) {
      return match[1].trim();
    }
    return null;
  }

  const phoneNumber = extractPhoneNumber(contact);

  return (
    <StoreContainer
      onMouseEnter={() => locationState.setHighlightedLocation(id)}
      onMouseLeave={() => locationState.setHighlightedLocation(null)}
      tabIndex={0}
    >
      <ItemRow>
        <ItemCol>
          <Heading>
            <StoreLink storeName={name}>
              <span dangerouslySetInnerHTML={{ __html: name }} />
            </StoreLink>
          </Heading>
        </ItemCol>
        <ItemCol>
          <StoreLink storeName={name}>
            <StoreLinkContent />
          </StoreLink>
        </ItemCol>
      </ItemRow>

      <ItemRow>
        <ItemCol>
          <div dangerouslySetInnerHTML={{ __html: address1 }} />
          {phoneNumber && (
            <div dangerouslySetInnerHTML={{ __html: phoneNumber }} />
          )}
        </ItemCol>
        <ItemCol>
          <div dangerouslySetInnerHTML={{ __html: openHours }} />
        </ItemCol>
      </ItemRow>
    </StoreContainer>
  );
};

const StoreList = ({
  stores,
  userLocation,
  setUserLocation,
  distances,
  ...rest
}) => (
  <StoreListContainer {...rest}>
    <UserLocation setUserLocation={setUserLocation} />
    <ul>
      {stores?.length ? (
        stores.map((store, index) => (
          <Store
            key={index}
            storeNr={index + 1}
            store={store}
            distances={distances}
          />
        ))
      ) : (
        <NoResultsHeading size="h3">
          {t('Inga butiker hittades')}
        </NoResultsHeading>
      )}
    </ul>
  </StoreListContainer>
);

export default StoreList;
