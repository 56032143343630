import React from 'react';
import { styled } from 'linaria/react';
import { Hero } from '../../ui/Headings';
import BorderHeading from '../../ui/BorderHeading';
import Container, { Row } from '../../Layout/Container';
import { theme } from '../../Theme';
import CategoryCard from './CategoryCard';

/*==============================================================================
  # Styles
==============================================================================*/

const StyledContainer = styled(Container)`
  overflow-x: hidden;
`;

const ItemRow = styled(Row)`
  margin-left: -2.5px;

  ${theme.below.md} {
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow: auto;
    margin-left: -2.5px;
  }

  ${theme.above.md} {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const StartPageCategories = ({ title, marginTop, children }) => {
  const featuredCategories = children.map((child, index) => ({
    ...child.props.category.value,
    key: index
  }));
  return (
    <StyledContainer marginTop={marginTop.value.value}>
      {title.value && (
        <BorderHeading align="center">
          <Hero size="h3">{title.value.value}</Hero>
        </BorderHeading>
      )}

      <ItemRow>
        {featuredCategories &&
          featuredCategories.map(cat => (
            <CategoryCard key={cat.key} cat={cat} />
          ))}
      </ItemRow>
    </StyledContainer>
  );
};

export default StartPageCategories;
