import BrandLogo from '../ui/BrandLogo';
import BrandsList from './Components/BrandsList';
import CategoryCard from './Components/CategoryCard';
import Categories from './Components/Categories';
import CategoryGrid from './Components/CategoryGrid';
import CoverImage from './Components/CoverImage';
import HeroText from './Components/HeroText';
import HeroBlocks from './Components/HeroBlocks';
import TitleText from './Components/TitleText';
import TextImage from './Components/TextImage';
import { ProductCard } from '../CategoryPage/ProductCard';
import Products from './Components/Products';
import Newsletter from './Components/Newsletter';
import TextPlate from './Components/TextPlate';
import PodGrid from './Components/PodGrid';
import ImagePod from './Components/ImagePod';
import Inspiration from './Components/Inspiration';
import CTA from './Components/CTA';
import StoreLocatorComponent from './Components/StoreLocatorComponent';
import Link from '../utility/Link';
import Image from '@jetshop/ui/Image';
import HeroBlock from './Components/HeroBlock';
import TextBlock from './Components/TextBlock';

export const components = {
  LINK: Link,
  IMAGE: Image,
  TEXTPLATE: TextPlate,
  NEWSLETTER: Newsletter,
  HEROTEXT: HeroText,
  HEROBLOCK: HeroBlock,
  HEROBLOCKS: HeroBlocks,
  TITLETEXT: TitleText,
  CTA: CTA,
  TEXTIMAGE: TextImage,
  COVERIMAGE: CoverImage,
  INSPIRATION: Inspiration,
  BRANDSLIST: BrandsList,
  IMAGEPOD: ImagePod,
  PODGRID: PodGrid,
  BRAND: BrandLogo,
  FEATUREDPRODUCT: ProductCard,
  PRODUCTS: Products,
  FEATUREDCATEGORY: CategoryCard,
  CATEGORIES: Categories,
  CATEGORYGRID: CategoryGrid,
  STORELOCATOR: StoreLocatorComponent,
  TEXT: TextBlock
};

export const getComponent = key => components?.[key] || null;
