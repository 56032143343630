import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import Container, { Row, Col } from '../../Layout/Container';
import { Mega } from '../../ui/Headings';
import BrandLogo from '../../ui/BrandLogo';
import Text from '../../ui/Text';
import Link from '../../utility/Link';
import { theme } from '../../Theme';

/*==============================================================================
  # Styles
==============================================================================*/

const StyledRow = styled(Row)`
  position: relative;
  color: ${theme.colors.black};

  p {
    color: ${theme.colors.black};
  }

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100%);
    height: 100%;
    background-color: ${theme.colors.bggrey};

    ${theme.above.sm} {
      width: calc(100%);
    }
  }

  ${theme.above.md} {
    &.text-center,
    &.text-center .link-container {
      text-align: center;
    }

    &.text-right,
    &.text-right .link-container {
      text-align: right;
    }

    &.low {
      .image-col {
        min-height: 400px;
      }
    }
  }
`;

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${theme.colors.slategrey};

  ${theme.below.md} {
    text-align: center;
  }
`;

const ImageCol = styled(StyledCol)`
  ${theme.below.md} {
    padding-left: 0px;
    padding-right: 0px;
  }

  ${theme.above.md} {
    min-height: 500px;

    &.image-1 {
      order: 1;

      .image-container {
        left: 0px;
      }
    }

    &.image-2 {
      order: 2;

      .image-container {
        right: 0px;
      }
    }
  }

  .image-container {
    position: relative;
    width: 100%;
    height: 400px;

    ${theme.above.md} {
      position: absolute;
      top: 0px;
      height: 100%;
      width: calc(100% + 15px);
    }
  }
`;

const StyledImage = styled(Image)`
  width: 100% !important;
  height: 100% !important;
  padding: 0px !important;

  img {
    object-fit: cover !important;
    font-family: 'object-fit: cover' !important;
  }
`;

const ContentCol = styled(StyledCol)`
  ${theme.above.md} {
    &.image-1 {
      order: 2;
      padding-right: 30px;
    }

    &.image-2 {
      order: 1;
    }
  }

  .inner {
    max-width: 540px;
    margin: 0 auto;
    padding: 30px 30px 40px;

    ${theme.above.md} {
      padding: 60px 30px;
    }

    ${theme.above.lg} {
      padding: 80px 45px;
    }

    ${theme.above.xl} {
      padding: 100px 60px;
    }
  }

  .link-container {
    text-align: center;

    > a {
      letter-spacing: 0.5px;
    }

    ${theme.above.md} {
      text-align: right;
    }
  }

  .link {
    color: ${theme.colors.slategrey};
    text-decoration: none;
    text-transform: none;
    margin-left: auto;
    transition: all 350ms ${theme.easings.secondary};

    &::hover {
      color: ${theme.colors.black};
    }

    ${theme.above.md} {
      &::before {
        display: block;
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
      }
    }
  }
`;

const StyledText = styled(Text)`
  display: inline-block;
  width: 100%;
  margin: 20px auto 25px;
  ${theme.fontSizes.description}

  ${theme.above.md} {
    margin-bottom: 30px auto 35px;
  }

  &.text-image-html {
    margin: 0;
    h1 {
      ${theme.fontSizes.mega}
    }
    h2 {
      ${theme.fontSizes.hero}
    }
    p {
      margin: 20px auto 25px;
    }
  }
`;

/*==============================================================================
  # Component
==============================================================================*/

const TextImage = ({
  logo,
  title,
  text,
  titleHtml,
  textHtml,
  marginTop,
  imageAlignment,
  modifiedDate,
  children
}) => {
  const image = children.filter(item => item.key === 'image-0');

  const imageSrc = image[0].props['image']?.value?.value;
  const imageAlt = image[0].props['imageAlt']?.value?.value;

  const link = children.filter(item => item.key === 'link-1');
  const linkTitle = link[0]?.props['linkTitle']?.value?.value;
  const linkUrl = link[0]?.props['linkUrl']?.value?.value;

  const defaultTitle = title?.value?.value;
  const defaultText = text?.value?.value;
  const htmlTitle = titleHtml?.value?.value;
  const htmlText = textHtml?.value?.value;

  const showHtmlTitle = htmlTitle && !(htmlTitle?.split('<br>')?.length === 2);
  const showHtmlText = htmlText && !(htmlText?.split('<br>')?.length === 2);

  let imageOrderClass =
    imageAlignment?.value?.value === 'left' ? 'image-1' : 'image-2';

  return (
    <Container
      marginTop={marginTop?.value?.value ? marginTop.value.value : true}
    >
      <StyledRow>
        <ImageCol
          col={12}
          md={6}
          lg={7}
          className={`image-col ${imageOrderClass}`}
        >
          <div className="image-container">
            {imageSrc && (
              <StyledImage
                alt={imageAlt || title?.value?.value || ''}
                src={imageSrc}
                quality={80}
                sizes={[1, 1, 1, 1 / 2, 1 / 2]}
                aspect="1:1"
                modifiedDate={modifiedDate}
              />
            )}
          </div>
        </ImageCol>

        <ContentCol col={12} md={6} lg={5} className={imageOrderClass}>
          <div className="inner">
            {logo?.value?.value && (
              <BrandLogo
                style={{ margin: '0px auto 20px' }}
                width={120}
                src={logo.value.value}
              />
            )}

            {showHtmlTitle ? (
              <StyledText content={htmlTitle} className="text-image-html" />
            ) : (
              defaultTitle && <Mega>{defaultTitle}</Mega>
            )}
            {showHtmlText ? (
              <StyledText content={htmlText} className="text-image-html" />
            ) : (
              defaultText && <StyledText content={defaultText} />
            )}

            {linkUrl && linkTitle && (
              <div className="link-container">
                <Link className="link sub-heading-size" to={linkUrl}>
                  {linkTitle}
                </Link>
              </div>
            )}
          </div>
        </ContentCol>
      </StyledRow>
    </Container>
  );
};

export default TextImage;
