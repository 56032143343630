import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import LoadingPage from '../LoadingPage';
import startPageQuery from './StartPageQuery.gql';
import { ContentEditorPage } from './StartPage';

const StartPageById = ({ startPageId }) => {
  const result = useQuery(startPageQuery, {
    variables: {
      startPageId: startPageId
    }
  });

  if (result.loading) return <LoadingPage />;
  if (!result.data) return null;
  return (
    <ContentEditorPage
      result={result?.data?.route}
      path="/"
    ></ContentEditorPage>
  );
};

export default StartPageById;
